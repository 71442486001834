import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

const state = {
    meta: {
        baruCount: 0,
        // prosesCount: 0,
        pendingCount: 0,
        selesaiCount: 0,
        batalCount: 0,
        allCount: 0,
        currentPage: 1,
        perPage: 10
    },
    selectedTabIndex: 'Baru',
    records: {},
    record: {},
    statuses: [],
    sortOrder: [],
    uiFlags: {
        isFetching: false,
        isFetchingItem: false,
        isUpdating: false,
        isUpdatingUnreadCount: false,
        isCreating: true,
    },
    bulkEditModal: {
        selectedComplaints: []
    },
    issues: []
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
