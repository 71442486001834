function findStatusByTabKey(tab) {
    switch (tab) {
        case 'Baru':
            return 2;
        // case 'Proses':
        //     return 3;
        case 'Pending':
            return 4;
        case 'Selesai':
            return 5;
        default:
            return 6;
    }
}

export const getters = {
    getComplaintsMeta($state) {
        return $state.meta
    },
    getComplaints($state) {
        if (Object.keys($state.records).length == 0) return [];
        return $state.sortOrder.map(orderId => $state.records[orderId]);
    },
    getStatuses($state) {
        return $state.statuses
    },
    getSelectedTabIndex: $state => $state.selectedTabIndex,
    getUiFlags: $state => $state.uiFlags,
    getComplaint: $state => $state.record,
    getSelectedComplaints: $state => $state.bulkEditModal.selectedComplaints,
    getSelectedTabIndex2: $state => findStatusByTabKey($state.selectedTabIndex),
    getIssues: $state => $state.issues
}