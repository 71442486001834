<template>
  <woot-modal :show.sync="show" :on-close="onCancel">
    <div class="h-auto overflow-auto flex flex-col mb-30 pb-10">
      <woot-modal-header :header-title="'Tambah Work Order'" />
      <div class="mt-2 px-8" style="min-height: 500px">
        <choose-work-order @work-order-master="onWorkOrderMasterChange" />
      </div>
      <woot-modal :show.sync="showCreateWoForm" :on-close="toggleCreateWoForm">
        <div class="h-auto overflow-auto flex flex-col mb-30 pb-10">
          <woot-modal-header :header-title="'Tambah Work Order'" />
          <div class="mt-2 px-8">
            <div class="gap-2 flex flex-row">
              <div class="text-nowrap flex flex-col w-1/2 mr-4">
                <label class="mb-1">Estimasi</label>
                <input
                  class="text mb-0"
                  type="text"
                  v-model="workingTime"
                  readonly
                />
              </div>
              <div class="text-nowrap flex flex-col w-1/2">
                <label class="mb-1">Status WO </label>
                <select
                  class="mb-0"
                  v-model="woStatusSelected"
                  @change="woStatusChange"
                >
                  <option disabled value="">Pilih status WO..</option>
                  <option
                    v-for="status in wostatus"
                    :key="status.key"
                    :value="status.key"
                  >
                    {{ status.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="text-nowrap flex flex-col mt-5">
              <label class="mb-1">Staff Mengerjakan</label>
              <multiselect
                v-model="staffSelected"
                :max-height="160"
                :options="staffs"
                track-by="id"
                label="full_name"
                :multiple="true"
                :close-on-select="true"
                :clear-on-select="true"
                :hide-selected="true"
                placeholder="Pilih staff.."
                :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                @select="onStaffChange"
                @remove="onStaffChange"
                class="custom-multiselect"
              />
            </div>
            <div class="text-wrap flex flex-col w-full mb-5">
              <label class="mb-1">Deskripsi WO </label>
              <input
                class="text-wrap mb-0"
                type="text"
                v-model="workOrderDescription"
                @input="woDescriptionChange"
              />
            </div>
            <div v-if="needPelanggan" class="gap-2 flex flex-row mt-5">
              <div class="text-nowrap flex flex-col w-full">
                <label class="mb-1">Pilih Pelanggan</label>
                <multiselect
                  v-model="selectedPelanggan"
                  :options="pelangganList"
                  track-by="pelanggan_id"
                  label="full_name"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :hide-selected="true"
                  @search-change="fetchPelanggan"
                  placeholder="Cari pelanggan..."
                  @select="onPelangganSelect"
                  @remove="onPelangganSelect"
                />
              </div>
            </div>
            <div v-if="truncatedItems.length > 0" class="flex flex-wrap">
              <div class="gap-2 flex flex-row mt-2">
                <div class="text-nowrap flex flex-col w-full mr-4">
                  <div class="flex items-center mb-4">
                    <span class="mr-4 text-gray-500 font-bold">Tindakan</span>
                    <div class="flex-grow border-t border-gray-300"></div>
                  </div>
                </div>
              </div>
              <div
                v-for="(item, index) in truncatedItems"
                :key="item.id"
                class="text-nowrap flex flex-col w-full mb-2"
              >
                <span
                  class="text mb-0 bg-gray-100 dark:bg-slate-800 py-2 w-full flex justify-between items-center"
                >
                  <span class="text-xs w-1/2">
                    {{ index + 1 }}.{{ item.truncatedDescription }}
                  </span>
                  <span
                    class="text-xs bg-blue-200 text-blue-800 px-2 py-1 rounded"
                  >
                    {{
                      item.need_file
                        ? 'Butuh Dokumentasi'
                        : 'Tidak Butuh Dokumentasi'
                    }}
                  </span>
                </span>
              </div>
            </div>
            <div
              class="flex flex-row justify-center gap-2 pt-5 px-0 w-full mb-5"
            >
              <woot-button
                class="button clear"
                type="submit"
                @click="showCreateWorkOrder"
              >
                Buat Work Order
              </woot-button>
            </div>
          </div>
          <woot-modal
            :show.sync="showModalWO"
            :on-close="onCancelCreateWorkOrder"
          >
            <div class="h-auto overflow-auto flex flex-col mb-30 pb-6">
              <woot-modal-header :header-title="'Konfirmasi'" />
              <div class="mt-2 px-8">
                <p>
                  Lanjutkan membuat work order? Pastikan semua data sudah benar.
                </p>
                <div class="flex justify-end mt-4">
                  <div class="flex flex-row justify-end gap-2 px-0 w-full">
                    <woot-button @click="createWorkOrder" class="button clear">
                      <template v-if="isLoading">
                        <spinner />
                      </template>
                      <template v-else> Ya </template>
                    </woot-button>
                    <woot-button
                      @click="showModalWO = false"
                      class="button clear"
                    >
                      Batal
                    </woot-button>
                  </div>
                </div>
              </div>
            </div>
          </woot-modal>
        </div>
      </woot-modal>
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from 'shared/components/Spinner.vue';
import ChooseWorkOrder from './ChooseWorkOrder.vue';

export default {
  data() {
    return {
      wostatus: [
        { key: 'pending', name: 'Pending' },
        { key: 'active', name: 'On Progress' },
        { key: 'finish', name: 'Finish' },
      ],
      isLoading: false,
      staffs: [],
      staffSelected: [],
      showModalWO: false,
      payload: {},
      woSelected: '',
      woTypeSelected: '',
      woStatusSelected: '',
      wo: [],
      workingTime: '',
      workOrderDescription: '',
      needPelanggan: false,
      selectedPelanggan: null,
      pelangganList: [],
      showCreateWoForm: false,
      listWoType: [],
    };
  },
  components: {
    Spinner,
    ChooseWorkOrder,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      currentUserID: 'getCurrentUserID',
      token: 'openaccess/getToken',
      apiUrl: 'openaccess/getApiUrl',
      openaccessUserData: 'openaccess/getUserData',
    }),
    staffIds() {
      return this.staffSelected.map(staff => staff.id);
    },
    selectedWOItems() {
      const selectedWO = this.wo.find(wot => wot.key === this.woSelected.key);
      return selectedWO ? selectedWO.items : [];
    },
    truncatedItems() {
      return this.selectedWOItems.map(item => ({
        ...item,
        truncatedDescription:
          item.description.length > 50
            ? item.description.slice(0, 50) + '...'
            : item.description,
      }));
    },
  },
  methods: {
    toggleCreateWoForm() {
      this.showCreateWoForm = !this.showCreateWoForm;
    },
    showCreateWorkOrder() {
      this.showModalWO = true;
    },
    onCancelCreateWorkOrder() {
      this.showModalWO = !this.showModalWO;
    },
    onCancel() {
      this.$emit('cancel');
    },
    woStatusChange() {
      this.updatePayload();
    },
    woDescriptionChange() {
      this.updatePayload();
    },
    onWorkOrderTypeChange(selected) {
      this.woTypeSelected = selected;
      // this.fetchWorkOrderMaster(selected.id);
    },
    onWorkOrderMasterChange(selected) {
      this.woSelected = selected;
      this.needPelanggan = selected.type?.need_pelanggan || false;

      this.toggleCreateWoForm();

      //   if (this.needPelanggan && this.pelangganList.length == 0) {
      //     this.fetchPelanggan();
      //   }
      this.updatePayload();
    },
    onStaffChange() {
      this.updatePayload();
    },
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    onPelangganSelect(selected) {
      this.selectedPelanggan = selected;
      this.updatePayload();
    },
    async fetchWoType() {
      try {
        const response = await axios.get(
          `${this.apiUrl}/api/work-order/work-order-master-type`,
          {
            params: {
              current_page: 1,
              per_page: 1000,
              company_id: 3,
              order_type: 'type',
              order: 'ascending',
            },
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );

        this.listWoType = response.data.data;
      } catch (error) {}
    },
    async fetchPelanggan(search = '') {
      try {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(async () => {
          const response = await axios.get(
            `${this.apiUrl}/api/v2/pelanggan/fetch-pelanggan?search=${search}&type=default&company_id=3&per_page=10&page=1`,
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
            }
          );
          this.pelangganList = response.data.data.map(pelanggan => {
            return {
              ...pelanggan,
              full_name:
                pelanggan.user && pelanggan.user.full_name
                  ? pelanggan.user.full_name
                  : 'Nama tidak tersedia',
            };
          });
        }, 500);
      } catch (error) {
        console.error('Error fetching pelanggan:', error);
      }
    },
    formatDate(date) {
      const padZero = num => num.toString().padStart(2, '0');
      const month = padZero(date.getMonth() + 1);
      const day = padZero(date.getDate());
      const year = date.getFullYear();
      const hours = padZero(date.getHours());
      const minutes = padZero(date.getMinutes());
      const seconds = padZero(date.getSeconds());
      return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
    },
    async createWorkOrder() {
      this.isLoading = true;
      try {
        const response = await axios.post(
          `${this.apiUrl}/api/work-order/work-order-users`,
          this.payload,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        if ((response.status = 200)) {
          this.showAlert('Berhasil membuat Work Order.');
          this.showModalWO = false;
          location.reload();
        }
      } catch (error) {
        this.showAlert('Gagal membuat Work Order.');
        console.error('Gagal mengirim data:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async getStaffTeknisi() {
      try {
        const response = await axios.get(
          `${this.apiUrl}/api/user/staff/pagination?page=1&per_page=36&order_by=&order=ascending&search=&company_id=3&role=18&position=`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        this.staffs = response.data.data.map(staff => ({
          id: staff.id,
          full_name: staff.full_name,
        }));
      } catch (error) {
        console.error('Error fetching work order types:', error);
      }
    },
    async fetchWorkOrderMaster(type_id = '') {
      try {
        const response = await axios.get(
          `${this.apiUrl}/api/work-order/work-order-masters?page=1&per_page=10&type_id=${type_id}&company_id=3&with=type,items`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        this.wo = response.data.data.map(wot => ({
          key: wot.id,
          name: wot.name,
          working_time: wot.working_time,
          items: wot.items,
          add_more_item: wot.add_more_item,
          can_multiple_active: wot.can_multiple_active,
          change_estimated_time: wot.change_estimated_time,
          company_id: wot.company_id,
          created_at: wot.created_at,
          created_by: wot.created_by,
          description: wot.description,
          need_customer_visit: wot.need_customer_visit,
          require_ttd_pelanggan: wot.require_ttd_pelanggan,
          require_real_panjang_kabel: wot.require_real_panjang_kabel,
          type_id: wot.type_id,
          status: 'pending',
          type: {
            ...wot.type,
            need_pelanggan: wot.type?.need_pelanggan || false,
          },
        }));
      } catch (error) {
        console.error('Error fetching work order types:', error);
      }
    },
    updatePayload() {
      const selectedWo = this.woSelected;
      if (selectedWo && this.staffSelected) {
        this.payload = {
          add_more_item: selectedWo.add_more_item,
          can_multiple_active: selectedWo.can_multiple_active,
          change_estimated_time: selectedWo.change_estimated_time,
          created_at: selectedWo.created_at,
          created_by: selectedWo.created_by,
          deleted_at: null,
          description: this.workOrderDescription,
          id: selectedWo.id,
          items: selectedWo.items.map(item => ({
            id: item.id,
            description: item.description,
            need_file: item.need_file,
            order: item.order,
          })),
          pelanggan_id: this.selectedPelanggan
            ? this.selectedPelanggan.pelanggan_id
            : null,
          name: selectedWo.name,
          need_customer_visit: selectedWo.need_customer_visit,
          require_real_panjang_kabel: selectedWo.require_real_panjang_kabel,
          require_ttd_pelanggan: selectedWo.require_real_panjang_kabel,
          status: this.woStatusSelected,
          type_id: selectedWo.type_id,
          user_id: this.staffIds,
          work_order_master_id: selectedWo.key,
          working_time: selectedWo.working_time,
          start_at: this.formatDate(new Date()),
        };
      }
    },

    async initPageData() {
      try {
        if (this.token) {
          this.fetchPelanggan();
          this.fetchWorkOrderMaster();
          this.fetchWoType();
          this.getStaffTeknisi();
        }
      } catch (error) {
        console.error('Error initializing data', error);
      }
    },
  },
  mounted() {
    this.initPageData();
    this.$store.dispatch('openaccess/getWorkOrderType');
  },
  watch: {
    woSelected(newVal) {
      const selectedWO = this.woSelected;
      this.workingTime = selectedWO ? selectedWO.working_time : '';
      this.workOrderDescription = selectedWO ? selectedWO.description : '';

      this.staffSelected = [
        this.staffs.find(staff => staff.id == this.openaccessUserData.id),
      ];
      if (!this.woStatusSelected) {
        this.woStatusSelected = 'active';
      }

      this.updatePayload();
    },
  },
};
</script>

<style scoped>
.custom-multiselect {
  max-width: 100%;
  white-space: normal;
}

.multiselect__tags {
  display: flex;
  flex-wrap: wrap;
}

.multiselect__tag {
  white-space: nowrap;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
