import HdrApi from '../../api/hdr'
import types from '../mutation-types';

const state = {
    records: []
};

const getters = {
    getRecords: state => state.records
};

const mutations = {
    [types.SET_HDR_RECORDS]($state, data) {
        $state.records = data
    }
};

const actions = {
    get: async ({commit}) => {
        try {
            const {
                data: {
                    payload
                }
            } = await HdrApi.get()

            commit(types.SET_HDR_RECORDS, payload)
        } catch (error) {
            
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
