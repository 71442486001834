<template>
  <div class="w-full h-full overflow-auto">
    <div class="flex flex-col h-full">
      <header
        class="bg-white dark:bg-slate-900 border-b border-slate-50 dark:border-slate-800"
      >
        <div class="flex w-full py-2 px-4 justify-between items-center">
          <div class="flex flex-col">
            <h1
              class="m-0 text-lg text-slate-900 dark:text-slate-100 whitespace-nowrap mt-1 my-0 mx-2"
            >
              Edit Work Order
            </h1>
            <!-- <div class="flex mx-2 w-full mt-2">
                            <div v-if="finishAt" class="flex flex-col w-full">
                                <p class="text-xs text-green-600">WO pada komplain ini telah selesai pada {{
                                    finishAt }}.
                                    <br>
                                    Dalam : {{ timeDiff }} menit | Estimasi : {{ estimatedTime }} menit
                                </p>
                                <div class="flex flex-row w-full">
                                    <p class="text-xs text-slate-200 font-bold">Catatan :
                                    </p>
                                    <p class="text-xs ml-1"> {{ finishWONote }}
                                    </p>
                                </div>
                            </div>
                        </div> -->
            <div class="flex mx-2 w-full mb-1">
              <div
                v-if="woStatusText === 'Validated'"
                class="text-nowrap flex flex-col w-full"
              >
                <span class="text-green-300 text-xs"
                  >Validated : {{ validatedUser.validated_at }}</span
                >
                <span class="text-green-300 text-xs"
                  >Oleh : {{ validatedUser.full_name }}</span
                >
              </div>
            </div>
            <!-- <div class="flex mx-2 w-full mt-2">
                            <div v-if="finishAt" class="flex flex-col w-full">
                                <p class="text-xs text-green-600">WO pada komplain ini telah selesai pada {{
                                    finishAt }}.
                                    <br>
                                    Dalam : {{ timeDiff }} menit | Estimasi : {{ estimatedTime }} menit
                                </p>
                                <div class="flex flex-row w-full">
                                    <p class="text-xs text-slate-200 font-bold">Catatan :
                                    </p>
                                    <p class="text-xs ml-1"> {{ finishWONote }}
                                    </p>
                                </div>
                            </div>
                        </div> -->
            <div v-if="!validatedUser.validated_at" class="flex mx-2 w-full">
              <div v-if="finishAt" class="flex flex-col w-full">
                <p class="text-xs text-green-600">
                  WO pada komplain ini telah selesai pada {{ finishAt }}.
                  <br />
                  Dalam : {{ timeDiff }} menit | Estimasi :
                  {{ estimatedTime }} menit
                </p>
                <div class="flex flex-row w-full">
                  <p class="text-xs font-bold">Catatan :</p>
                  <p class="text-xs ml-1">{{ finishWONote }}</p>
                </div>
              </div>
            </div>
          </div>
          <woot-button class="clear" type="submit" @click="goBack">
            Kembali
          </woot-button>
        </div>
      </header>
      <section class="conversation-page bg-white dark:bg-slate-900">
        <div
          v-if="isLoading"
          class="flex justify-center items-center text-center text-white py-4"
        >
          <spinner /> Sedang memuat data...
        </div>
        <div v-else class="px-6">
          <div class="gap-2 flex flex-row mt-5">
            <div class="text-nowrap flex flex-col w-1/2 mr-4">
              <label class="mr-3 mb-1">Nama WO :</label>
              <span
                class="text mb-0 bg-gray-100 dark:bg-slate-800 p-2 w-full truncate"
              >
                {{ workOrderName }}
              </span>
            </div>
            <div class="text-nowrap flex flex-col w-1/2">
              <label class="mr-3 mb-1">Deskripsi WO :</label>
              <span
                class="text mb-0 bg-gray-100 dark:bg-slate-800 p-2 w-full truncate"
              >
                {{ workOrderDescription }}
              </span>
            </div>
          </div>
          <div class="gap-2 flex flex-row mt-5">
            <div class="text-nowrap flex flex-col w-1/2 mr-4">
              <label class="mr-3 mb-1">Staff Bertugas :</label>
              <span
                class="text mb-0 bg-gray-100 dark:bg-slate-800 p-2 w-full truncate"
              >
                {{ workingStaff }}
              </span>
            </div>
            <div class="text-nowrap flex flex-col w-1/2">
              <label class="mr-3 mb-1">Dibuat Oleh :</label>
              <span
                class="text mb-0 bg-gray-100 dark:bg-slate-800 p-2 w-full truncate"
              >
                {{ createdBy }}
              </span>
            </div>
          </div>
          <div class="gap-2 flex flex-row mt-5">
            <div class="text-nowrap flex flex-col w-1/2 mr-4">
              <label class="mr-3 mb-1">Jam Mulai :</label>
              <span class="text mb-0 bg-gray-100 dark:bg-slate-800 p-2 w-full">
                {{ startAt }}
              </span>
            </div>
            <div class="text-nowrap flex flex-col w-1/2">
              <label class="mr-3 mb-1">Jam Selesai :</label>
              <span class="text mb-0 bg-gray-100 dark:bg-slate-800 p-2 w-full">
                {{ finishAt ? finishAt : 'WO ini belum diselesaikan' }}
              </span>
            </div>
          </div>
          <div class="gap-2 flex flex-row mt-5">
            <div class="text-nowrap flex flex-col w-1/2 mr-4">
              <label class="mr-3 mb-1">Estimasi Waktu (Menit) :</label>
              <span class="text mb-0 bg-gray-100 dark:bg-slate-800 p-2 w-full">
                {{ estimatedTime }}
              </span>
            </div>
            <div class="text-nowrap flex flex-col w-1/2">
              <label class="mr-3 mb-1">Status :</label>
              <div class="mb-0 bg-gray-100 dark:bg-slate-800 p-2 w-full">
                <span
                  :class="{
                    'text-yellow-400': woStatusText === 'Pending',
                    'text-green-600': woStatusText === 'On Progress',
                    'text-green-400': woStatusText === 'Finish',
                    'text-red-400': woStatusText === 'Cancel',
                    'text-green-300': woStatusText === 'Validated',
                  }"
                >
                  {{ woStatusText }}
                </span>
              </div>
            </div>
          </div>
          <div
            v-if="woStatusText === 'Finish' || woStatusText === 'Validated'"
            class="gap-2 flex flex-row mt-5"
          >
            <div class="text-nowrap flex flex-col w-full">
              <label class="mr-3 mb-1">Waktu Selesai (Menit) :</label>
              <div class="mb-0 bg-gray-100 dark:bg-slate-800 p-2 w-full">
                <span> {{ finishWorkingTime }} </span>
              </div>
            </div>
          </div>
          <div v-if="pelangganName && siteId" class="gap-2 flex flex-row mt-5">
            <div class="text-nowrap flex flex-col w-1/2 mr-4">
              <label class="mr-3 mb-1">Nama Pelanggan :</label>
              <span class="text mb-0 bg-gray-100 dark:bg-slate-800 p-2 w-full">
                {{ pelangganName }}
              </span>
            </div>
            <div class="text-nowrap flex flex-col w-1/2">
              <label class="mr-3 mb-1">Site ID :</label>
              <span class="text mb-0 bg-gray-100 dark:bg-slate-800 p-2 w-full">
                {{ siteId }}
              </span>
            </div>
          </div>
          <div v-if="woStatus === 'pending'" class="mt-5">
            <div class="my-5">
              <woot-button @click="showWorkOrderModal"
                >Mulai Kerjakan WO</woot-button
              >
            </div>
            <div class="gap-2 flex flex-row">
              <div class="text-nowrap flex flex-col w-full">
                <div class="flex items-center my-4">
                  <span class="mr-4 text-gray-500 font-bold"
                    >List Tindakan</span
                  >
                  <div class="flex-grow border-t border-gray-300"></div>
                  <woot-button
                    type="submit"
                    class="button clear"
                    @click="showAddItem"
                  >
                    Tambah Tindakan
                  </woot-button>
                </div>
              </div>
            </div>

            <div v-if="items && items.length" class="mb-5">
              <ol class="space-y-3 m-0">
                <li v-for="(item, index) in items" :key="item.id" class="py-2">
                  <div class="flex items-center justify-between">
                    <div>
                      <p class="text-sm text-gray-600">
                        {{ index + 1 }}.{{ item.description }}
                      </p>
                      <div v-if="item.status" class="text-sm text-gray-500">
                        Status:
                        <span
                          :class="{
                            'text-yellow-600': item.status === 'pending',
                            'text-green-600': item.status === 'finish',
                          }"
                        >
                          {{ item.status_text || item.status }}
                        </span>
                      </div>
                    </div>
                    <woot-button
                      type="submit"
                      size="small"
                      color-scheme="alert"
                      :icon="'delete'"
                      @click="showDeleteItem(item.id)"
                    />
                  </div>
                </li>
              </ol>
            </div>
          </div>
          <div v-else-if="woStatus === 'cancel'" class="mt-5">
            <div class="flex justify-center my-5">
              <span class="text-sm font-italic"
                >Work order ini telah dibatalkan</span
              >
            </div>
          </div>
          <div v-else>
            <div class="gap-2 flex flex-row mt-5">
              <div class="text-nowrap flex flex-col w-full">
                <div class="flex items-center my-4">
                  <span class="mr-4 text-gray-500 font-bold"
                    >List Tindakan</span
                  >
                  <div class="flex-grow border-t border-gray-300"></div>
                  <woot-button
                    v-if="woStatus != 'finish' && woStatus != 'validated'"
                    type="submit"
                    class="button clear"
                    @click="showAddItem"
                  >
                    Tambah Tindakan
                  </woot-button>
                </div>
              </div>
            </div>
            <div v-if="items && items.length" class="mb-5">
              <ol class="space-y-3 m-0">
                <li v-for="(item, index) in items" :key="item.id" class="py-2">
                  <div class="flex items-center justify-between">
                    <div>
                      <p class="text-sm text-gray-600">
                        {{ index + 1 }}. {{ item.description }}
                      </p>
                      <div v-if="item.status" class="text-sm text-gray-500">
                        Status:
                        <span
                          :class="{
                            'text-yellow-600': item.status === 'pending',
                            'text-green-600': item.status === 'finish',
                          }"
                        >
                          {{ item.status_text || item.status }}
                        </span>
                      </div>
                      <input
                        class="my-3"
                        v-if="
                          item.need_file &&
                          woStatus != 'finish' &&
                          woStatus != 'validated'
                        "
                        type="text"
                        placeholder="Paste Image Disini"
                        @paste="onPasteImageDocumentation(item.id, $event)"
                      />
                      <input
                        v-if="
                          item.need_file &&
                          woStatus != 'finish' &&
                          woStatus != 'validated'
                        "
                        type="file"
                        accept="image/*"
                        @change="uploadDocumentation(item.id, $event)"
                      />
                    </div>
                    <div class="mt-2 flex items-center">
                      <template
                        v-if="item.status === 'finish' && item.files.length"
                      >
                        <div class="flex flex-wrap">
                          <div v-if="imageLoading" class="flex items-center justify-center">
                              <spinner />
                            </div>
                          <img
                            v-for="(file, index) in item.files"
                            :key="index"
                            :src="file.medium_thumb"
                            alt="Gambar Dokumentasi"
                            class="mt-2 w-20 h-20 mr-2 cursor-pointer"
                            @click="showImage(file.path, file.id)"
                          />
                        </div>
                      </template>
                      <template v-if="item.status === 'pending'">
                        <woot-button
                          v-if="!item.need_file"
                          type="submit"
                          size="small"
                          @click="showFinishItem(item.id)"
                        >
                          Finish
                        </woot-button>
                      </template>
                      <woot-button
                        v-if="
                          woStatusText != 'Finish' &&
                          woStatusText != 'Validated'
                        "
                        type="submit"
                        size="small"
                        class="ml-2"
                        color-scheme="alert"
                        :icon="'delete'"
                        @click="showDeleteItem(item.id)"
                      />
                    </div>
                  </div>
                </li>
              </ol>
            </div>
            <div v-else>
              <p class="text-sm text-gray-500">
                Tidak ada tindakan yang tersedia.
              </p>
            </div>
            <div class="flex-grow border-t border-gray-300 mt-3"></div>
            <div
              v-if="woStatus !== 'finish'"
              class="my-4 flex justify-center w-full"
            >
              <div
                class="flex flex-col items-center"
                v-if="workOrderId && allItemsFinished()"
              >
                <div class="text-nowrap flex flex-col w-80">
                  <label class="mb-1">Keterangan </label>
                  <textarea
                    class="mb-0"
                    v-model="woComment"
                    placeholder="Optional..."
                  ></textarea>
                </div>
                <div class="my-4">
                  <woot-button @click="showFinishWorkOrder">
                    Selesaikan Work Order
                  </woot-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <woot-modal :show.sync="showFullImg" :on-close="closeFullImg">
        <div class="h-auto overflow-auto flex flex-col mb-30 py-30">
          <div class="my-12 px-8 flex flex-col justify-center items-center">
            <img
              :src="selectedImage"
              alt="No image to shown."
              class="max-w-full max-h-full mx-auto"
            />
            <spinner v-if="deleteLoading" class="mt-4" />
            <woot-button
              v-else
              class="mt-4"
              color-scheme="alert"
              :icon="'delete'"
              size="tiny"
              @click="deleteItemsImg"
            />
          </div>
        </div>
      </woot-modal>
      <woot-modal :show.sync="showStartWO" :on-close="closeStartWO">
        <div class="h-auto overflow-auto flex flex-col mb-30 pb-6">
          <woot-modal-header :header-title="'Konfirmasi'" />
          <div class="mt-2 px-8">
            <p>
              Mulai mengerjakan work order? Jam mulai akan dicatat saat tindakan
              ini dimulai.
            </p>
            <div class="flex justify-end mt-4">
              <div class="flex flex-row justify-end gap-2 px-0 w-full">
                <woot-button @click="startWorkOrder" class="button clear">
                  <template v-if="isLoading">
                    <spinner />
                  </template>
                  <template v-else> Ya </template>
                </woot-button>
                <woot-button @click="closeStartWO" class="button clear">
                  Tidak
                </woot-button>
              </div>
            </div>
          </div>
        </div>
      </woot-modal>
      <woot-modal :show.sync="showFinishWOModal" :on-close="closeFinishWO">
        <div class="h-auto overflow-auto flex flex-col mb-30 pb-6">
          <woot-modal-header :header-title="'Konfirmasi'" />
          <div class="mt-2 px-8">
            <p>Yakin ingin menyelesaikan work order ini?</p>
            <div class="flex justify-end mt-4">
              <div class="flex flex-row justify-end gap-2 px-0 w-full">
                <woot-button @click="finishWorkOrder" class="button clear">
                  <template v-if="isLoading">
                    <spinner />
                  </template>
                  <template v-else> Ya </template>
                </woot-button>
                <woot-button @click="closeFinishWO" class="button clear">
                  Tidak
                </woot-button>
              </div>
            </div>
          </div>
        </div>
      </woot-modal>
      <woot-modal :show.sync="showModal" :on-close="closeFinishItem">
        <div class="h-auto overflow-auto flex flex-col mb-30 pb-6">
          <woot-modal-header :header-title="'Konfirmasi'" />
          <div class="mt-2 px-8">
            <p>Finish item pekerjaan ini?</p>
            <div class="flex justify-end mt-4">
              <div class="flex flex-row justify-end gap-2 px-0 w-full">
                <woot-button @click="finishWOItems" class="button clear">
                  <template v-if="isLoading">
                    <spinner />
                  </template>
                  <template v-else> Ya </template>
                </woot-button>
                <woot-button @click="closeFinishItem" class="button clear">
                  Tidak
                </woot-button>
              </div>
            </div>
          </div>
        </div>
      </woot-modal>
      <woot-modal :show.sync="showDeleteModal" :on-close="closeDeleteItem">
        <div class="h-auto overflow-auto flex flex-col mb-30 pb-6">
          <woot-modal-header :header-title="'Konfirmasi'" />
          <div class="mt-2 px-8">
            <p>Hapus item work order ini?</p>
            <div class="flex justify-end mt-4">
              <div class="flex flex-row justify-end gap-2 px-0 w-full">
                <woot-button @click="deleteWOItems" class="button clear">
                  <template v-if="isLoading">
                    <spinner />
                  </template>
                  <template v-else> Ya </template>
                </woot-button>
                <woot-button @click="closeDeleteItem" class="button clear">
                  Tidak
                </woot-button>
              </div>
            </div>
          </div>
        </div>
      </woot-modal>
      <template>
        <woot-modal :show.sync="showAddItemModal" :on-close="closeAddItem">
          <div class="h-auto overflow-auto flex flex-col mb-30 pb-6">
            <woot-modal-header :header-title="'Tambah Item Pekerjaan'" />
            <div class="mt-2 px-8">
              <div class="text-wrap flex flex-col w-full">
                <label class="mb-2">Deskripsi item pekerjaan</label>
                <div class="flex flex-row items-center relative">
                  <input
                    class="text-wrap mb-0 w-full"
                    type="text"
                    v-model="newItemDescription"
                    placeholder="Masukkan deskripsi item pekerjaan"
                    @input="fetchWorkOrderItems(newItemDescription)"
                  />
                </div>
                <div v-if="itemsLoading" class="flex justify-center my-4">
                  <template>
                    <spinner />
                  </template>
                </div>
                <div
                  v-if="showWorkOrderItems && workOrderItemsList.length"
                  class="mt-4 bg-gray-100 rounded"
                >
                  <ul class="ml-0 mb-0">
                    <li
                      v-for="item in workOrderItemsList"
                      :key="item.id"
                      @click="selectItem(item.item)"
                      class="wo list-group-item"
                    >
                      {{ item.item }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="flex flex-row items-center mt-4">
                <label class="checkbox-wrapper m-0">
                  <input class="checkbox" type="checkbox" v-model="needFile" />
                  Item ini memerlukan dokumentasi?
                </label>
              </div>

              <div class="flex justify-end mt-4">
                <div class="flex flex-row justify-end gap-2 px-0 w-full">
                  <woot-button @click="saveNewItem" class="button">
                    <template v-if="isLoading">
                      <spinner />
                    </template>
                    <template v-else> Simpan </template>
                  </woot-button>
                  <woot-button @click="closeAddItem" class="button clear">
                    Batal
                  </woot-button>
                </div>
              </div>
            </div>
          </div>
        </woot-modal>
      </template>
    </div>
  </div>
</template>

<script>
import Spinner from 'shared/components/Spinner.vue';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      items: [],
      isLoading: false,
      itemsLoading: false,
      imageLoading: false,
      deleteLoading: false,
      showStartWO: false,
      showModal: false,
      showDeleteModal: false,
      showFinishWOModal: false,
      needFile: false,
      showFullImg: false,
      showAddItemModal: false,
      selectedItemId: null,
      workOrderName: '',
      workOrderDescription: '',
      workingStaff: '',
      createdBy: '',
      estimatedTime: '',
      startAt: '',
      imageUrl: '',
      finishAt: '',
      woStatus: '',
      woStatusText: '',
      workOrderId: '',
      woMasterId: '',
      woUserId: '',
      woComment: '',
      newItemDescription: '',
      selectedImage: '',
      pelangganName: '',
      siteId: '',
      finishWorkingtime: '',
      validatedUser: {
        full_name: '',
        validated_at: '',
      },
      workOrderItemsList: [],
      showWorkOrderItems: false,
      selectedImageId: '',
    };
  },
  computed: {
    ...mapGetters({
      currentUserID: 'getCurrentUserID',
      token: 'openaccess/getToken',
      apiUrl: 'openaccess/getApiUrl',
    }),
  },
  components: {
    Spinner,
  },
  methods: {
    async deleteItemsImg() {
      this.deleteLoading = true;
      try {
        const response = await axios.delete(
          `${this.apiUrl}/api/file/${this.selectedImageId}`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        if (response.status == 200) {
          this.showFullImg = false;
          this.fetchCurrentWorkOrder(this.workOrderId);
          this.fetchComplaint(this.$route.params.complaintId);
          this.showAlert(`Gambar dihapus`);
        }
      } catch (error) {
        console.error('Error deleting Image:', error);
      } finally {
        this.deleteLoading = false;
      }
    },
    async fetchWorkOrderItems(searchKeyword = '') {
      clearTimeout(this.debounce);

      this.debounce = setTimeout(async () => {
        try {
          if (searchKeyword.trim() === '') {
            this.workOrderItemsList = [];
            this.showWorkOrderItems = false;
            return;
          }
          this.itemsLoading = true;
          const response = await axios.get(
            `${this.apiUrl}/api/work-order/work-order-user-items/${this.woMasterId}`,
            {
              params: {
                per_page: 20,
                page: 1,
                search: searchKeyword,
              },
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
            }
          );

          this.workOrderItemsList = response.data.data.map(item => ({
            id: item.id,
            item: item.description,
          }));
          this.showWorkOrderItems = true;
        } catch (error) {
          console.error('Error fetching work orders:', error);
          this.workOrderItemsList = [];
          this.showWorkOrderItems = false;
        } finally {
          this.itemsLoading = false;
        }
      }, 500);
    },
    selectItem(itemDescription) {
      this.newItemDescription = itemDescription;
      this.showWorkOrderItems = false;
    },
    toggleWorkOrderList() {
      this.showWorkOrderItems = !this.showWorkOrderItems;
    },
    goBack() {
      this.$router.push(
        `/app/accounts/${this.$route.params.accountId}/work-orders`
      );
    },
    allItemsFinished() {
      return this.items.every(item => item.status === 'finish');
    },
    showAddItem() {
      this.showAddItemModal = true;
    },
    showImage(imageUrl, id) {
      this.selectedImage = imageUrl;
      this.selectedImageId = id;
      this.showFullImg = true;
    },
    showFinishItem(itemId) {
      this.selectedItemId = itemId;
      this.showModal = true;
    },
    showDeleteItem(itemId) {
      this.selectedItemId = itemId;
      this.showDeleteModal = true;
    },
    showFinishWorkOrder() {
      this.showFinishWOModal = true;
    },
    showWorkOrderModal() {
      this.showStartWO = true;
    },
    closeDeleteItem() {
      this.showDeleteModal = false;
    },
    closeAddItem() {
      this.showAddItemModal = false;
    },
    closeFullImg() {
      this.showFullImg = false;
    },
    closeStartWO() {
      this.showStartWO = false;
    },
    closeFinishWO() {
      this.showFinishWOModal = false;
    },
    closeFinishItem() {
      this.showModal = false;
    },
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },

    async finishWOItems() {
      this.isLoading = true;
      try {
        const formData = new FormData();
        formData.append('status', 'finish');
        formData.append('file', '');

        const response = await axios.post(
          `${this.apiUrl}/api/work-order/work-order-user-items/${this.selectedItemId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        if (response.status == 200) {
          this.showModal = false;
          this.fetchCurrentWorkOrder(this.workOrderId);
          this.showAlert('Berhasil Finish Item Work Order.');
        }
      } catch (error) {
        this.showAlert('Gagal Finish Item Work Order.');
        console.error('Error finishing action:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async deleteWOItems() {
      this.isLoading = true;
      try {
        const response = await axios.delete(
          `${this.apiUrl}/api/work-order/work-order-user-items/${this.selectedItemId}`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        if (response.status == 200) {
          this.showDeleteModal = false;
          this.fetchCurrentWorkOrder(this.workOrderId);
          this.showAlert('Item Work Order Dihapus.');
        }
      } catch (error) {
        this.showAlert('Gagal menghapus item WO.');
        console.error('Error deleting action:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async onPasteImageDocumentation(itemId, event) {
      let items = event.clipboardData.items;

      if (items.length > 0 && items[0].type.startsWith('image/')) {
        this.showAlert(`Sedang mengunggah file dokumentasi`);
        this.imageLoading = true;
      }

      for (const item of items) {
        if (item.type.startsWith('image/')) {
          const blob = item.getAsFile();

          const formData = new FormData();
          formData.append('status', 'finish');
          formData.append('file', blob);

          const response = await axios.post(
            `${this.apiUrl}/api/work-order/work-order-user-items/${itemId}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
            }
          );

          if (response.status === 200) {
            this.showAlert(`Berhasil mengupload file dokumentasi.`);
            this.imageLoading = false;
            setTimeout(
              () => this.fetchCurrentWorkOrder(this.workOrderId),
              3000
            );
          } else {
            this.showAlert(`Gagal mengupload file dokumentasi.`);
          }
        }
      }
    },

    async uploadDocumentation(itemId, event) {
      try {
        const files = event.target.files;
        for (let i = 0; i < files.length; i++) {
          const formData = new FormData();
          formData.append('status', 'finish');
          formData.append('file', files[i]);

          const response = await axios.post(
            `${this.apiUrl}/api/work-order/work-order-user-items/${itemId}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
            }
          );

          if (response.status === 200) {
            this.showAlert(`Berhasil mengupload file dokumentasi.`);
          } else {
            this.showAlert(`Gagal mengupload file dokumentasi.`);
          }
        }
        this.fetchCurrentWorkOrder(this.workOrderId);
      } catch (error) {
        this.showAlert('Terjadi kesalahan saat mengupload file.');
        console.error('Kesalahan saat menyelesaikan item work order,', error);
      }
    },
    async finishWorkOrder() {
      this.isLoading = true;
      try {
        const response = await axios.put(
          `${this.apiUrl}/api/work-order/work-order-users/${this.workOrderId}/finish`,
          {
            comment: this.woComment,
            change_estimated_time: false,
            new_working_time: 0,
            current_working_time: this.estimatedTime,
            original_working_time: 20,
            real_panjang_kabel: 0,
            _method: 'PUT',
          },
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );

        if (response.status == 200) {
          this.showFinishWOModal = false;
          this.fetchCurrentWorkOrder(this.workOrderId);
          this.showAlert('Berhasil menyelesaikan Work Order.');
        }
      } catch (error) {
        this.showAlert('Gagal menyelesaikan Work Order.');
        console.error('Error completing work order:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async startWorkOrder() {
      this.isLoading = true;
      try {
        const response = await axios.put(
          `${this.apiUrl}/api/work-order/work-order-users/${this.workOrderId}/start`,
          {},
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        if (response.status == 200) {
          this.showStartWO = false;
          this.fetchCurrentWorkOrder(this.workOrderId);
          this.showAlert('Berhasil memulai Work Order');
        }
      } catch (error) {
        this.showAlert('Gagal memulai Work Order.');
        console.error('Kesalahan saat memulai work order:', error);
      } finally {
        this.isLoading = false;
      }
    },
    timeDifference(start_at, finish_at) {
      if (start_at && finish_at) {
        const startTime = new Date(start_at);
        const finishTime = new Date(finish_at);
        const timeDifference = Math.floor((finishTime - startTime) / 60000);
        return timeDifference;
      }
      return null;
    },
    async fetchCurrentWorkOrder(workOrderId) {
      this.isLoading = true;
      try {
        const response = await axios.get(
          `${this.apiUrl}/api/work-order/work-order-users/detail-wo/${workOrderId}`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        const currentWO = response.data.data;
        this.workOrderName = currentWO.name;
        this.workOrderDescription = currentWO.description;
        this.workingStaff = currentWO.users
          .map(user => user.full_name)
          .join(', ');
        this.createdBy = currentWO.created_user.full_name;
        this.estimatedTime = currentWO.working_time;
        this.startAt = currentWO.start_at;
        this.finishAt = currentWO.finish_at;
        this.woStatus = currentWO.status;
        this.woStatusText = currentWO.status_text;
        this.items = currentWO.items || [];
        this.timeDiff = this.timeDifference(
          currentWO.start_at,
          currentWO.finish_at
        );
        this.finishWONote = currentWO.comment;
        this.woMasterId = currentWO.work_order_master_id;
        this.woUserId = currentWO.id;
        this.finishWorkingTime = currentWO.estimated_working_time;

        if (currentWO.validated) {
          this.validatedUser = {
            full_name: currentWO.validated.full_name,
            validated_at: currentWO.validated_at,
          };
        } else {
          this.validatedUser = {};
        }

        if (currentWO.pelanggan != null) {
          const pelangganName = currentWO.pelanggan.user.full_name;
          const siteId = currentWO.pelanggan.presale.presale_master.site_id;
          this.pelangganName = pelangganName;
          this.siteId = siteId;
        } else {
          return null;
        }
      } catch (error) {
        console.error('Error fetching work order:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async saveNewItem() {
      this.isLoading = true;
      const payload = {
        description: this.newItemDescription,
        need_file: this.needFile ? 1 : 0,
        status: 'pending',
        type: 'add_item',
        work_order_master_id: this.woMasterId,
        work_order_user_id: this.woUserId,
      };
      try {
        const response = await axios.post(
          `${this.apiUrl}/api/work-order/work-order-user-items`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );

        this.items.push(response.data.data);
        this.closeAddItem();
        this.newItemDescription = '';
        this.needFile = false;
        this.showAlert(`Berhasil Menambahkan Item.`);
      } catch (error) {
        console.error('Error adding new item:', error);
      } finally {
        this.isLoading = false;
      }
    },

    async initPageData() {
      try {
        this.workOrderId = this.$route.params.workOrdersId;
        if (this.token) {
          await this.fetchCurrentWorkOrder(this.workOrderId);
          this.fetchWorkOrderItems();
        }
      } catch (error) {
        console.error('Error initializing data', error);
      }
    },
  },
  mounted() {
    this.initPageData();
  },
};
</script>

<style scoped>
.conversation-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-height: max-content;
}

.wo.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.wo.list-group-item {
  background-color: hsl(200, 7%, 8.8%);
  border: 1px solid #697076;
  /* padding: 0.75rem 1.25rem; */
  position: center;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  color: white;
}

.wo.list-group-item:hover {
  background-color: hsl(200, 7%, 12%);
}
</style>
