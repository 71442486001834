<template>
  <div class="w-full h-full overflow-auto">
    <div class="flex flex-col h-full">
      <header
        class="bg-white dark:bg-slate-900 border-b border-slate-50 dark:border-slate-800"
      >
        <div
          class="w-full py-2 px-4 justify-between flex-row flex items-center justify-center"
        >
          <div class="flex flex-col">
            <div class="flex flex-row items-center mt-2">
              <h1
                class="text-lg text-slate-900 dark:text-slate-100 whitespace-nowrap mx-2"
              >
                Edit Komplain
              </h1>
              <div v-if="typeSelected === 'Individu' && convId">
                <span class="text-lg text-slate-100"
                  >( Percakapan :
                  <router-link :to="toConversation">
                    <span>#{{ convId }}</span>
                  </router-link>
                  )
                </span>
              </div>
            </div>
            <div class="flex mx-2 w-full mb-1">
              <div
                v-if="woStatusText === 'Validated'"
                class="text-nowrap flex flex-col w-full"
              >
                <span class="text-green-300 text-xs"
                  >Validated : {{ validatedUser.validated_at }}</span
                >
                <span class="text-green-300 text-xs"
                  >Oleh : {{ validatedUser.full_name }}</span
                >
              </div>
            </div>
            <div v-if="!validatedUser.validated_at" class="flex mx-2 w-full">
              <div v-if="finishAt" class="flex flex-col w-full">
                <p class="text-xs text-green-600">
                  WO pada komplain ini telah selesai pada {{ finishAt }}.
                  <br />
                  Dalam : {{ timeDiff }} menit | Estimasi :
                  {{ estimatedTime }} menit
                </p>
                <div class="flex flex-row w-full">
                  <p class="text-xs text-slate-200 font-bold">Catatan :</p>
                  <p class="text-xs ml-1">{{ finishWONote }}</p>
                </div>
              </div>
            </div>
          </div>
          <woot-button class="clear" type="submit" @click="goBack">
            Kembali
          </woot-button>
        </div>
      </header>
      <section class="conversation-page bg-white dark:bg-slate-900">
        <div v-if="hasNullCustomerIds">
          <div
            class="flex w-full bg-red-400 items-center h-12 gap-4 text-white dark:text-white text-xs py-3 px-4 justify-center"
          >
            <span>
              Ada beberapa data pelanggan yang tidak sinkron,
              <button @click="toggleTable" class="text-blue-500 underline ml-1">
                Lihat
              </button>
            </span>
          </div>
          <woot-modal :show.sync="isTableVisible" :on-close="closeTable" size="medium" >
            <div class="h-auto overflow-auto flex flex-col mb-30 pb-6">
              <woot-modal-header :header-title="'Data Pelanggan Komplain'" />
              <div class="mt-2 px-8">
                <table class="min-w-full border border-gray-300">
                  <thead>
                    <tr>
                      <th class="border border-gray-300 px-4 py-2 text-center">
                        Nama
                      </th>
                      <th class="border border-gray-300 px-4 py-2 text-center">
                        No Telepon
                      </th>
                      <th class="border border-gray-300 px-4 py-2 text-center">
                        HomeNet CMS
                      </th>
                      <th class="border border-gray-300 px-4 py-2 text-center">
                        Open Access
                      </th>
                      <th
                        class="border border-gray-300 px-4 py-2 text-center"
                        v-if="complaint.conversations.length > 0"
                      >
                        Conv
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(contact, index) in complaint.contacts"
                      :key="index"
                    >
                      <td class="border border-gray-200 px-4 py-2">
                        {{ contact.name }}
                      </td>
                      <td class="border border-gray-200 px-4 py-2">
                        {{ contact.phone_number }}
                      </td>
                      <td class="border border-gray-200 px-4 py-2 text-center">
                        <span
                          v-if="
                            find_complaint_has_conversations(contact.id)
                              .customer_id != null
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            class="w-5 h-5 text-green-500"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </span>
                        <span v-else>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            class="w-5 h-5 text-red-500"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </span>
                      </td>
                      <td class="border border-gray-200 px-4 py-2 text-center">
                        <span
                          v-if="
                            find_complaint_has_conversations(contact.id)
                              .openaccess_customer_id != null
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            class="w-5 h-5 text-green-500"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </span>
                        <span v-else>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            class="w-5 h-5 text-red-500"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </span>
                      </td>
                      <td
                        class="border border-gray-200 px-4 py-2 text-center"
                        v-if="complaint.conversations.length > 0"
                      >
                        <woot-button
                          class="button clear"
                          type="submit"
                          @click="
                            toConversationTable(
                              find_complaint_has_conversations(contact.id)
                                .conversation_id
                            )
                          "
                        >
                          #{{
                            find_complaint_has_conversations(contact.id)
                              .conversation_id
                          }}
                        </woot-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </woot-modal>
        </div>
        <div class="px-6">
          <div class="gap-2 flex flex-row mt-5">
            <div class="text-nowrap flex flex-col w-1/2 mr-4">
              <div class="flex items-center my-4">
                <span class="mr-4 text-gray-500 font-bold"
                  >Detail Pelanggan</span
                >
                <div class="flex-grow border-t border-gray-300"></div>
              </div>
            </div>
            <div class="text-nowrap flex flex-row w-1/2 items-center">
              <label class="mr-3 mb-0">Jenis Komplain</label>
              <select class="mb-0" v-model="typeSelected">
                <option disabled value="">Pilih jenis komplain..</option>
                <option v-for="type in types" :key="type.key" :value="type.key">
                  {{ type.name }}
                </option>
              </select>
            </div>
          </div>
          <div
            v-if="typeSelected === 'Massal'"
            class="gap-2 flex flex-row mt-3"
          >
            <div class="text-nowrap flex flex-row items-center w-full">
              <label class="mr-3">Komplain Massal </label>
              <input class="text mb-0" type="text" v-model="massOption" />
            </div>
          </div>
          <div class="gap-2 flex flex-row mt-5">
            <div class="text-nowrap flex flex-col w-1/2 mr-4">
              <label class="mr-3 mb-1">Nama Kontak</label>
              <span class="text mb-0 bg-gray-100 dark:bg-slate-800 p-2 w-full">
                {{ userName }}
              </span>
            </div>
            <div class="text-nowrap flex flex-col w-1/2">
              <label class="mb-1">Masalah </label>
              <select class="mb-0" v-model="issueSelected">
                <option disabled value="">Pilih masalah..</option>
                <option
                  v-for="issue in issues"
                  :key="issue.key"
                  :value="issue.key"
                >
                  {{ issue.name }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="issueSelected === 8" class="gap-2 flex flex-row mt-5">
            <div class="text-nowrap flex flex-col w-full">
              <label class="mb-1">Keterangan Masalah </label>
              <input type="text" class="text mb-0" v-model="otherIssue" />
            </div>
          </div>
          <div class="gap-2 flex flex-row mt-5">
            <div class="text-nowrap flex flex-col w-1/2 mr-4">
              <label class="mr-3 mb-1">Paket</label>
              <span class="text mb-0 bg-gray-100 dark:bg-slate-800 p-2 w-full">
                {{ packageName ? packageName : 'Data paket tidak ditemukan' }}
              </span>
            </div>
            <div class="text-nowrap flex flex-col w-1/2">
              <label class="mb-1">Status Komplain</label>
              <select class="mb-0" v-model="statusSelected">
                <option disabled value="">Pilih status..</option>
                <option
                  v-for="status in statuses"
                  :key="status.key"
                  :value="status.key"
                >
                  {{ status.name }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="statusSelected === 4" class="gap-2 flex flex-row mt-2">
            <div class="w-full pt-2">
              <select class="mb-0" v-model="confirmSelected">
                <option disabled value="">Pilih...</option>
                <option>Menunggu Konfirmasi Pelanggan</option>
                <option>Menunggu Hasil Teknisi</option>
                <option>Menunggu Hasil NOC</option>
              </select>
            </div>
          </div>
          <div v-if="issueSelected === 2" class="gap-2 flex flex-row mt-5">
            <div class="text-nowrap flex flex-col w-full">
              <label class="mb-2">Jangka Waktu Mati</label>
              <Datepicker
                v-model="customDateRange"
                range
                type="datetime"
                format="YYYY-MM-DD HH:mm"
                :confirm="true"
                :not-auto-close="false"
                confirm-text="Pilih"
                class="w-full"
                @change="onChangeRange"
              />
            </div>
          </div>
          <div class="gap-2 flex flex-row mt-5">
            <div class="text-nowrap flex flex-col w-1/2 mr-4">
              <label class="mr-3 mb-1">Nomor Telepon</label>
              <span class="text mb-0 bg-gray-100 dark:bg-slate-800 p-2 w-full">
                {{ phoneNumber }}
              </span>
            </div>
            <div class="text-nowrap flex flex-col w-1/2">
              <label class="mb-1">Tanggal Komplain </label>
              <input
                type="datetime-local"
                v-model="complaintDate"
                :editable="false"
                class="mb-0"
              />
            </div>
          </div>
          <div class="gap-2 flex flex-row mt-5">
            <div class="text-nowrap flex flex-col w-[50%] mr-4">
              <label class="mr-3 mb-1">Alamat</label>
              <span
                class="text mb-0 bg-gray-100 dark:bg-slate-800 p-2 w-full truncate"
              >
                {{ custAddress ? custAddress : 'Data alamat tidak ditemukan' }}
              </span>
            </div>
            <div class="text-nowrap flex flex-col w-[50%]">
              <label class="mb-1">Komplain Melalui</label>
              <select class="mb-0" v-model="sourcesSelected">
                <option disabled value="">Pilih komplain melalui...</option>
                <option
                  v-for="source in sources"
                  :key="source.key"
                  :value="source.key"
                >
                  {{ source.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="gap-2 flex flex-row mt-5">
            <div class="text-nowrap flex flex-col w-1/2 mr-4">
              <label class="mr-3 mb-1">Foto</label>
              <div v-if="uploadedImages.length">
                <img
                  v-for="(image, index) in uploadedImages"
                  :key="index"
                  :src="image"
                  class="w-28 my-2 mr-2 cursor-pointer"
                  @click="showComplaintImage(image)"
                />
              </div>

              <div v-else>
                <p>Tidak ada gambar tersedia.</p>
              </div>
            </div>
            <div class="text-nowrap flex flex-col w-1/2">
              <label class="mb-1">Keterangan </label>
              <textarea
                class="mb-0"
                v-model="complaintDesc"
                placeholder="Masukkan keterangan..."
              ></textarea>
            </div>
          </div>
          <div class="flex flex-row justify-end gap-2 pt-5 px-0 w-full">
            <woot-button
              class="button clear"
              type="submit"
              @click="showEditComplaintModal"
            >
              Update Data Komplain
            </woot-button>
          </div>
          <div v-if="!workOrderId">
            <div class="gap-2 flex flex-row mt-5">
              <div class="text-nowrap flex flex-col w-full mr-4">
                <div class="flex items-center">
                  <span class="mr-4 text-gray-500 font-bold">Work Order</span>
                  <div class="flex-grow border-t border-gray-300"></div>
                </div>
              </div>
            </div>
            <div class="gap-2 flex flex-col mt-5 w-full">
              <choose-work-order
                @work-order-master="onWorkOrderMasterChanged"
                :hideWoMasterOnChanged="true"
              />
            </div>
            <div class="gap-2 flex flex-row">
              <div class="text-nowrap flex flex-col w-1/2 mr-4">
                <label class="mb-1">Estimasi </label>
                <input
                  class="text mb-0"
                  type="text"
                  v-model="workingTime"
                  readonly
                />
              </div>
              <div class="text-nowrap flex flex-col w-1/2">
                <label class="mb-1">Status WO </label>
                <select
                  class="mb-0"
                  v-model="woStatusSelected"
                  @change="woStatusChange"
                >
                  <option disabled value="">Pilih status WO..</option>
                  <option
                    v-for="status in wostatus"
                    :key="status.key"
                    :value="status.key"
                  >
                    {{ status.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="text-nowrap flex flex-col mt-5">
              <label class="mb-1">Staff Mengerjakan</label>
              <multiselect
                v-model="staffSelected"
                :max-height="160"
                :options="staffs"
                track-by="id"
                label="full_name"
                :multiple="true"
                :close-on-select="true"
                :clear-on-select="true"
                :hide-selected="true"
                placeholder="Pilih staff.."
                :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                @select="onStaffChange"
                @remove="onStaffChange"
                class="custom-multiselect"
              />
            </div>
            <div class="text-wrap flex flex-col w-full mb-5">
              <label class="mb-1">Deskripsi WO </label>
              <input
                class="text-wrap mb-0"
                type="text"
                v-model="workOrderDescription"
                @input="woDescriptionChange"
              />
            </div>
            <div v-if="needPelanggan" class="gap-2 flex flex-row mt-5">
              <div class="text-nowrap flex flex-col w-full">
                <label class="mb-1">Pilih Pelanggan</label>
                <multiselect
                  v-model="selectedPelanggan"
                  :options="pelangganList"
                  track-by="pelanggan_id"
                  label="full_name"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :hide-selected="true"
                  placeholder="Pilih pelanggan..."
                  @select="onPelangganSelect"
                  @remove="onPelangganSelect"
                  @search-change="onSearchChange"
                />
              </div>
            </div>
            <div v-if="truncatedItems.length > 0" class="flex flex-wrap">
              <div class="gap-2 flex flex-row mt-2">
                <div class="text-nowrap flex flex-col w-full mr-4">
                  <div class="flex items-center mb-4">
                    <span class="mr-4 text-gray-500 font-bold">Tindakan</span>
                    <div class="flex-grow border-t border-gray-300"></div>
                  </div>
                </div>
              </div>
              <div
                v-for="item in truncatedItems"
                :key="item.id"
                class="text-nowrap flex flex-col w-full mb-2"
              >
                <span
                  class="text mb-0 bg-gray-100 dark:bg-slate-800 p-2 w-full flex justify-between items-center"
                >
                  <span class="text-xs w-1/2">
                    {{ item.truncatedDescription }}
                  </span>
                  <span
                    class="text-xs bg-blue-200 text-blue-800 px-2 py-1 rounded"
                  >
                    {{
                      item.need_file
                        ? 'Butuh Dokumentasi'
                        : 'Tidak Butuh Dokumentasi'
                    }}
                  </span>
                </span>
              </div>
            </div>
            <div
              class="flex flex-row justify-center gap-2 pt-5 px-0 w-full mb-5"
            >
              <woot-button
                class="button clear"
                type="submit"
                @click="showCreateWorkOrder"
                :disabled="
                  !woSelected ||
                  !woStatusSelected ||
                  !staffSelected.length ||
                  !truncatedItems.length
                "
              >
                Buat Work Order
              </woot-button>
            </div>
          </div>
          <div v-else>
            <div class="gap-2 flex flex-row mt-5">
              <div class="text-nowrap flex flex-col w-full">
                <div class="flex items-center mt-4">
                  <span class="mr-4 text-gray-500 font-bold"
                    >Detail Work Order</span
                  >
                  <div class="flex-grow border-t border-gray-300"></div>
                </div>
              </div>
            </div>
            <div class="gap-2 flex flex-row mt-5">
              <span class="mr-4 text-gray-500 italic text-sm"
                >Komplain ini sudah memiliki work order.</span
              >
            </div>
            <div class="gap-2 flex flex-row mt-5">
              <div class="text-nowrap flex flex-col w-1/2 mr-4">
                <label class="mr-3 mb-1">Nama WO :</label>
                <span
                  class="text mb-0 bg-gray-100 dark:bg-slate-800 p-2 w-full truncate"
                >
                  {{ workOrderName }}
                </span>
              </div>
              <div class="text-nowrap flex flex-col w-1/2">
                <label class="mr-3 mb-1">Deskripsi WO :</label>
                <span
                  class="text mb-0 bg-gray-100 dark:bg-slate-800 p-2 w-full truncate"
                >
                  {{ workOrderDescription }}
                </span>
              </div>
            </div>
            <div class="gap-2 flex flex-row mt-5">
              <div class="text-nowrap flex flex-col w-1/2 mr-4">
                <label class="mr-3 mb-1">Staff Bertugas :</label>
                <span
                  class="text mb-0 bg-gray-100 dark:bg-slate-800 p-2 w-full truncate"
                >
                  {{ workingStaff }}
                </span>
              </div>
              <div class="text-nowrap flex flex-col w-1/2">
                <label class="mr-3 mb-1">Dibuat Oleh :</label>
                <span
                  class="text mb-0 bg-gray-100 dark:bg-slate-800 p-2 w-full truncate"
                >
                  {{ createdBy }}
                </span>
              </div>
            </div>
            <div class="gap-2 flex flex-row mt-5">
              <div class="text-nowrap flex flex-col w-1/2 mr-4">
                <label class="mr-3 mb-1">Jam Mulai :</label>
                <span
                  class="text mb-0 bg-gray-100 dark:bg-slate-800 p-2 w-full"
                >
                  {{ startAt }}
                </span>
              </div>
              <div class="text-nowrap flex flex-col w-1/2">
                <label class="mr-3 mb-1">Jam Selesai :</label>
                <span
                  class="text mb-0 bg-gray-100 dark:bg-slate-800 p-2 w-full"
                >
                  {{ finishAt ? finishAt : 'WO ini belum diselesaikan' }}
                </span>
              </div>
            </div>
            <div class="gap-2 flex flex-row mt-5">
              <div class="text-nowrap flex flex-col w-1/2 mr-4">
                <label class="mr-3 mb-1">Estimasi Waktu :</label>
                <span
                  class="text mb-0 bg-gray-100 dark:bg-slate-800 p-2 w-full"
                >
                  {{ estimatedTime }}
                </span>
              </div>
              <div class="text-nowrap flex flex-col w-1/2">
                <label class="mr-3 mb-1">Status :</label>
                <div class="mb-0 bg-gray-100 dark:bg-slate-800 p-2 w-full">
                  <span
                    :class="{
                      'text-yellow-400': woStatusText === 'Pending',
                      'text-green-600': woStatusText === 'On Progress',
                      'text-green-400': woStatusText === 'Finish',
                      'text-red-400': woStatusText === 'Cancel',
                      'text-green-300': woStatusText === 'Validated',
                    }"
                  >
                    {{ woStatusText }}
                  </span>
                </div>
              </div>
            </div>
            <div
              v-if="pelangganName && siteId"
              class="gap-2 flex flex-row mt-5"
            >
              <div class="text-nowrap flex flex-col w-1/2 mr-4">
                <label class="mr-3 mb-1">Nama Pelanggan :</label>
                <span
                  class="text mb-0 bg-gray-100 dark:bg-slate-800 p-2 w-full"
                >
                  {{ pelangganName }}
                </span>
              </div>
              <div class="text-nowrap flex flex-col w-1/2">
                <label class="mr-3 mb-1">Site ID :</label>
                <span
                  class="text mb-0 bg-gray-100 dark:bg-slate-800 p-2 w-full"
                >
                  {{ siteId }}
                </span>
              </div>
            </div>
            <div v-if="woStatus === 'pending'" class="mt-5">
              <div class="my-5">
                <woot-button @click="showWorkOrderModal"
                  >Mulai Kerjakan WO</woot-button
                >
              </div>
              <div class="gap-2 flex flex-row">
                <div class="text-nowrap flex flex-col w-full">
                  <div class="flex items-center my-4">
                    <span class="mr-4 text-gray-500 font-bold"
                      >List Tindakan</span
                    >
                    <div class="flex-grow border-t border-gray-300"></div>
                    <woot-button
                      type="submit"
                      class="button clear"
                      @click="showAddItem"
                    >
                      Tambah Tindakan
                    </woot-button>
                  </div>
                </div>
              </div>
              <div v-if="items && items.length" class="mb-5">
                <ol class="space-y-3 m-0">
                  <li
                    v-for="(item, index) in items"
                    :key="item.id"
                    class="py-2"
                  >
                    <div class="flex items-center justify-between">
                      <div>
                        <p class="text-sm text-gray-600">
                          {{ index + 1 }}.{{ item.description }}
                        </p>
                        <div v-if="item.status" class="text-sm text-gray-500">
                          Status:
                          <span
                            :class="{
                              'text-yellow-600': item.status === 'pending',
                              'text-green-600': item.status === 'finish',
                            }"
                          >
                            {{ item.status_text || item.status }}
                          </span>
                        </div>
                      </div>
                      <woot-button
                        type="submit"
                        size="small"
                        color-scheme="alert"
                        :icon="'delete'"
                        @click="showDeleteItem(item.id)"
                      />
                    </div>
                  </li>
                </ol>
              </div>
            </div>
            <div v-else-if="woStatus === 'cancel'" class="mt-5">
              <div class="flex justify-center my-5">
                <span class="text-sm font-italic"
                  >Work order ini telah dibatalkan</span
                >
              </div>
            </div>
            <div v-else>
              <div class="gap-2 flex flex-row mt-5">
                <div class="text-nowrap flex flex-col w-full">
                  <div class="flex items-center my-4">
                    <span class="mr-4 text-gray-500 font-bold"
                      >List Tindakan</span
                    >
                    <div class="flex-grow border-t border-gray-300"></div>
                    <woot-button
                      v-if="woStatus != 'finish' && woStatus != 'validated'"
                      type="submit"
                      class="button clear"
                      @click="showAddItem"
                    >
                      Tambah Tindakan
                    </woot-button>
                  </div>
                </div>
              </div>
              <div v-if="items && items.length" class="mb-5">
                <ol class="space-y-3 m-0">
                  <li
                    v-for="(item, index) in items"
                    :key="item.id"
                    class="py-2"
                  >
                    <div class="flex items-center justify-between">
                      <div>
                        <p class="text-sm text-gray-600">
                          {{ index + 1 }}. {{ item.description }}
                        </p>
                        <div v-if="item.status" class="text-sm text-gray-500">
                          Status:
                          <span
                            :class="{
                              'text-yellow-600': item.status === 'pending',
                              'text-green-600': item.status === 'finish',
                            }"
                          >
                            {{ item.status_text || item.status }}
                          </span>
                        </div>
                        <input
                          class="my-3"
                          v-if="
                            item.need_file &&
                            woStatus != 'finish' &&
                            woStatus != 'validated'
                          "
                          type="text"
                          placeholder="Paste Image Disini"
                          @paste="onPasteImageDocumentation(item.id, $event)"
                        />
                        <input
                          v-if="
                            item.need_file &&
                            woStatus != 'finish' &&
                            woStatus != 'validated'
                          "
                          type="file"
                          accept="image/*"
                          @change="uploadDocumentation(item.id, $event)"
                        />
                      </div>
                      <div class="mt-2 flex items-center">
                        <template
                          v-if="item.status === 'finish' && item.files.length"
                        >
                          <div class="flex flex-wrap">
                            <div v-if="imageLoading" class="flex items-center justify-center">
                              <spinner />
                            </div>
                            <img
                              v-for="(file, index) in item.files"
                              :key="index"
                              :src="file.medium_thumb"
                              alt="Gambar Dokumentasi"
                              class="mt-2 w-20 h-20 mr-2 cursor-pointer"
                              @click="showImage(file.path, file.id)"
                            />
                          </div>
                        </template>
                        <template v-if="item.status === 'pending'">
                          <woot-button
                            v-if="!item.need_file"
                            type="submit"
                            size="small"
                            @click="showFinishItem(item.id)"
                          >
                            Finish
                          </woot-button>
                        </template>
                        <woot-button
                          v-if="
                            woStatusText != 'Finish' &&
                            woStatusText != 'Validated'
                          "
                          type="submit"
                          size="small"
                          class="ml-2"
                          color-scheme="alert"
                          :icon="'delete'"
                          @click="showDeleteItem(item.id)"
                        />
                      </div>
                    </div>
                  </li>
                </ol>
              </div>
              <div v-else>
                <p class="text-sm text-gray-500">
                  Tidak ada tindakan yang tersedia.
                </p>
              </div>
            </div>
          </div>
          <div class="flex-grow border-t border-gray-300 mt-3"></div>
          <div
            v-if="woStatus === 'active'"
            class="my-4 flex justify-center w-full"
          >
            <div
              class="flex flex-col items-center"
              v-if="workOrderId && allItemsFinished()"
            >
              <div class="text-nowrap flex flex-col w-80">
                <label class="mb-1">Keterangan </label>
                <textarea
                  class="mb-0"
                  v-model="woComment"
                  placeholder="Optional..."
                ></textarea>
              </div>
              <div class="my-4">
                <woot-button @click="showFinishWorkOrder">
                  Selesaikan Work Order
                </woot-button>
              </div>
            </div>
          </div>
          <template>
            <woot-modal :show.sync="showAddItemModal" :on-close="closeAddItem">
              <div class="h-auto overflow-auto flex flex-col mb-30 pb-6">
                <woot-modal-header :header-title="'Tambah Item Pekerjaan'" />
                <div class="mt-2 px-8">
                  <div class="text-wrap flex flex-col w-full">
                    <label class="mb-2">Deskripsi item pekerjaan</label>
                    <div class="flex flex-row items-center relative">
                      <input
                        class="text-wrap mb-0 w-full"
                        type="text"
                        v-model="newItemDescription"
                        placeholder="Masukkan deskripsi item pekerjaan"
                        @input="fetchWorkOrderItems(newItemDescription)"
                      />
                    </div>
                    <div v-if="itemsLoading" class="flex justify-center my-4">
                      <template>
                        <spinner />
                      </template>
                    </div>
                    <div
                      v-if="showWorkOrderItems && workOrderItemsList.length"
                      class="mt-4 bg-gray-100 rounded"
                    >
                      <ul class="ml-0 mb-0">
                        <li
                          v-for="item in workOrderItemsList"
                          :key="item.id"
                          @click="selectItem(item.item)"
                          class="wo list-group-item"
                        >
                          {{ item.item }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="flex flex-row items-center mt-4">
                    <label class="checkbox-wrapper m-0">
                      <input
                        class="checkbox"
                        type="checkbox"
                        v-model="needFile"
                      />
                      Item ini memerlukan dokumentasi?
                    </label>
                  </div>

                  <div class="flex justify-end mt-4">
                    <div class="flex flex-row justify-end gap-2 px-0 w-full">
                      <woot-button @click="saveNewItem" class="button">
                        <template v-if="isLoading">
                          <spinner />
                        </template>
                        <template v-else> Simpan </template>
                      </woot-button>
                      <woot-button @click="closeAddItem" class="button clear">
                        Batal
                      </woot-button>
                    </div>
                  </div>
                </div>
              </div>
            </woot-modal>
          </template>
          <woot-modal :show.sync="showStartWO" :on-close="closeStartWO">
            <div class="h-auto overflow-auto flex flex-col mb-30 pb-6">
              <woot-modal-header :header-title="'Konfirmasi'" />
              <div class="mt-2 px-8">
                <p>
                  Mulai mengerjakan work order? Jam mulai akan dicatat saat
                  tindakan ini dimulai.
                </p>
                <div class="flex justify-end mt-4">
                  <div class="flex flex-row justify-end gap-2 px-0 w-full">
                    <woot-button @click="startWorkOrder" class="button clear">
                      <template v-if="isLoading">
                        <spinner />
                      </template>
                      <template v-else> Ya </template>
                    </woot-button>
                    <woot-button @click="closeStartWO" class="button clear">
                      Tidak
                    </woot-button>
                  </div>
                </div>
              </div>
            </div>
          </woot-modal>
          <woot-modal :show.sync="showEditModal" :on-close="closeEditComplaint">
            <div class="h-auto overflow-auto flex flex-col mb-30 pb-6">
              <woot-modal-header :header-title="'Konfirmasi'" />
              <div class="mt-2 px-8">
                <p>Yakin ingin mengupdate data komplain ini?</p>
                <div class="flex justify-end mt-4">
                  <div
                    class="flex flex-row justify-end items-center gap-2 px-0 w-full"
                  >
                    <woot-button
                      @click="saveChanges"
                      class="button clear"
                      :disabled="isLoading"
                    >
                      <!-- <spinner class="mr-2" v-if="isLoading"/> Ya -->
                      <template v-if="isLoading">
                        <spinner />
                      </template>
                      <template v-else> Ya </template>
                    </woot-button>
                    <woot-button
                      @click="closeEditComplaint"
                      class="button clear"
                    >
                      Tidak
                    </woot-button>
                  </div>
                </div>
              </div>
            </div>
          </woot-modal>
          <woot-modal :show.sync="showFinishWOModal" :on-close="closeFinishWO">
            <div class="h-auto overflow-auto flex flex-col mb-30 pb-6">
              <woot-modal-header :header-title="'Konfirmasi'" />
              <div class="mt-2 px-8">
                <p>Yakin ingin menyelesaikan work order ini?</p>
                <div class="flex justify-end mt-4">
                  <div class="flex flex-row justify-end gap-2 px-0 w-full">
                    <woot-button @click="finishWorkOrder" class="button clear">
                      <template v-if="isLoading">
                        <spinner />
                      </template>
                      <template v-else> Ya </template>
                    </woot-button>
                    <woot-button @click="closeFinishWO" class="button clear">
                      Tidak
                    </woot-button>
                  </div>
                </div>
              </div>
            </div>
          </woot-modal>
          <woot-modal :show.sync="showModal" :on-close="closeFinishItem">
            <div class="h-auto overflow-auto flex flex-col mb-30 pb-6">
              <woot-modal-header :header-title="'Konfirmasi'" />
              <div class="mt-2 px-8">
                <p>Finish item pekerjaan ini?</p>
                <div class="flex justify-end mt-4">
                  <div class="flex flex-row justify-end gap-2 px-0 w-full">
                    <woot-button @click="finishWOItems" class="button clear">
                      <template v-if="isLoading">
                        <spinner />
                      </template>
                      <template v-else> Ya </template>
                    </woot-button>
                    <woot-button @click="closeFinishItem" class="button clear">
                      Tidak
                    </woot-button>
                  </div>
                </div>
              </div>
            </div>
          </woot-modal>
          <woot-modal :show.sync="showModalWO" :on-close="closeCreateWO">
            <div class="h-auto overflow-auto flex flex-col mb-30 pb-6">
              <woot-modal-header :header-title="'Konfirmasi'" />
              <div class="mt-2 px-8">
                <p>
                  Lanjutkan membuat work order? Pastikan semua data sudah benar.
                </p>
                <div class="flex justify-end mt-4">
                  <div class="flex flex-row justify-end gap-2 px-0 w-full">
                    <woot-button @click="createWorkOrder" class="button clear">
                      <template v-if="isLoading">
                        <spinner />
                      </template>
                      <template v-else> Ya </template>
                    </woot-button>
                    <woot-button @click="closeCreateWO" class="button clear">
                      Batal
                    </woot-button>
                  </div>
                </div>
              </div>
            </div>
          </woot-modal>
          <woot-modal :show.sync="showDeleteModal" :on-close="closeDeleteItem">
            <div class="h-auto overflow-auto flex flex-col mb-30 pb-6">
              <woot-modal-header :header-title="'Konfirmasi'" />
              <div class="mt-2 px-8">
                <p>Hapus item work order ini?</p>
                <div class="flex justify-end mt-4">
                  <div class="flex flex-row justify-end gap-2 px-0 w-full">
                    <woot-button @click="deleteWOItems" class="button clear">
                      <template v-if="isLoading">
                        <spinner />
                      </template>
                      <template v-else> Ya </template>
                    </woot-button>
                    <woot-button @click="closeDeleteItem" class="button clear">
                      Tidak
                    </woot-button>
                  </div>
                </div>
              </div>
            </div>
          </woot-modal>
          <woot-modal :show.sync="showFullImg" :on-close="closeFullImg">
            <div class="h-auto overflow-auto flex flex-col mb-30 py-30">
              <div class="my-12 px-8 flex flex-col justify-center items-center">
                <img
                  :src="selectedImage"
                  alt="No image to shown."
                  class="max-w-full max-h-full mx-auto"
                />
                <spinner v-if="deleteLoading" class="mt-4" />
                <woot-button
                  v-else
                  class="mt-4"
                  color-scheme="alert"
                  :icon="'delete'"
                  size="tiny"
                  @click="deleteItemsImg"
                />
              </div>
            </div>
          </woot-modal>
          <woot-modal
            :show.sync="showFullComplaintImg"
            :on-close="closeFullComplaintImg"
          >
            <div class="h-auto overflow-auto flex flex-col mb-30 py-30">
              <div class="my-12 px-8 flex flex-col justify-center items-center">
                <img
                  :src="selectedComplaintImage"
                  alt="No image to shown."
                  class="max-w-full max-h-full mx-auto"
                />
              </div>
            </div>
          </woot-modal>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Spinner from 'shared/components/Spinner.vue';
import axios from 'axios';
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';
import { frontendURL } from 'dashboard/helper/URLHelper';
import ChooseWorkOrder from './ChooseWorkOrder.vue';
import Datepicker from 'vue2-datepicker';

export default {
  mixins: [alertMixin],
  data() {
    return {
      wostatus: [
        { key: 'pending', name: 'Pending' },
        { key: 'active', name: 'On Progress' },
        { key: 'finish', name: 'Finish' },
      ],
      types: [
        { key: 'Individu', name: 'Individu' },
        { key: 'Massal', name: 'Massal' },
      ],
      issues: [],
      sources: [],
      wo: [],
      payload: {},
      items: [],
      staffs: [],
      staffSelected: [],
      pelangganList: [],
      uploadedImages: [],
      validatedUser: {
        full_name: '',
        validated_at: '',
      },
      listConversations: [],
      isTableVisible: false,
      showModal: false,
      showModalWO: false,
      showFinishWOModal: false,
      showEditModal: false,
      showStartWO: false,
      isLoading: false,
      itemsLoading: false,
      imageLoading: false,
      deleteLoading: false,
      needFile: false,
      showFullImg: false,
      showAddItemModal: false,
      showDeleteModal: false,
      showFullComplaintImg: false,
      selectedItemId: null,
      complaintId: null,
      selectedPelanggan: null,
      userName: '',
      phoneNumber: '',
      massOption: '',
      issueSelected: '',
      sourcesSelected: '',
      woStatusSelected: '',
      woSelected: '',
      statusSelected: '',
      typeSelected: '',
      customDateRange: [],
      confirmSelected: '',
      complaintDate: '',
      complaintDesc: '',
      workingTime: '',
      finishAt: '',
      woComment: '',
      workOrderName: '',
      workOrderDescription: '',
      workingStaff: '',
      createdBy: '',
      estimatedTime: '',
      startAt: '',
      finishAt: '',
      woStatus: '',
      woStatusText: '',
      workOrderId: '',
      currentWO: '',
      timeDiff: '',
      finishWONote: '',
      // token: '',
      woMasterId: '',
      woUserId: '',
      newItemDescription: '',
      selectedImage: '',
      selectedImageId: '',
      selectedComplaintImage: '',
      additionalInput: '',
      pelangganName: '',
      siteId: '',
      convId: '',
      customerId: '',
      oaCustomerId: '',
      firstName: '',
      custAddress: '',
      packageName: '',
      otherIssue: '',
      apiUrl: '',
      cmsApiUrl: '',
      cmsToken: '',
      paketBerjalanId: '',
      workOrderItemsList: [],
      showWorkOrderItems: false,
    };
  },
  watch: {
    woSelected(selectedWO) {
      this.workingTime = selectedWO ? selectedWO.working_time : '';
      this.workOrderDescription = selectedWO ? selectedWO.description : '';

      if (this.needPelanggan) {
        this.fetchPelanggan();
      }

      if (!this.woStatusSelected) {
        this.woStatusSelected = 'active';
        this.updatePayload();
      }
    },
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      currentUserID: 'getCurrentUserID',
      getAccount: 'accounts/getAccount',
      currentAccountId: 'getCurrentAccountId',
      complaintStatuses: 'complaints/getStatuses',
      complaint: 'complaints/getComplaint',
      token: 'openaccess/getToken',
      openaccessUserData: 'openaccess/getUserData',
    }),
    needPelanggan() {
      return this.woSelected.type?.need_pelanggan || false;
    },
    staffIds() {
      return this.staffSelected.map(staff => staff.id);
    },
    statuses() {
      return this.complaintStatuses
        .filter(status => status.id != 1)
        .map(status => ({
          key: status.id,
          name: status.name,
        }));
    },

    selectedWOItems() {
      const selectedWO = this.woSelected;
      return selectedWO ? selectedWO.items : [];
    },
    truncatedItems() {
      return this.selectedWOItems.map(item => ({
        ...item,
        truncatedDescription:
          item.description.length > 50
            ? item.description.slice(0, 50) + '...'
            : item.description,
      }));
    },
    toConversation() {
      const accountId = this.accountId;
      const conversationId = this.convId;
      return frontendURL(
        `accounts/${accountId}/conversations/${conversationId}`
      );
    },
    hasNullCustomerIds() {
      if (!this.complaint.complaint_has_conversations) return false;

      return this.complaint.complaint_has_conversations.some(
        complaint_has_conversation =>
          !complaint_has_conversation.customer_id ||
          !complaint_has_conversation.openaccess_customer_id
      );
    },
  },
  components: {
    Spinner,
    ChooseWorkOrder,
    Datepicker,
  },
  methods: {
    find_complaint_has_conversations(contact_id) {
      if (!this.complaint.complaint_has_conversations) return {};

      return this.complaint.complaint_has_conversations.find(
        item => item.contact_id === contact_id
      );
    },
    toConversationTable(conversationId) {
      const accountId = this.accountId;
      this.$router.push(
        `/app/accounts/${accountId}/conversations/${conversationId}`
      );
    },
    showComplaintImage(imageUrl) {
      this.selectedComplaintImage = imageUrl;
      this.showFullComplaintImg = true;
    },
    showImage(imageUrl, id) {
      this.selectedImage = imageUrl;
      this.selectedImageId = id;
      this.showFullImg = true;
    },
    goBack() {
      this.$router.push(
        `/app/accounts/${this.$route.params.accountId}/complaints`
      );
    },
    allItemsFinished() {
      return this.items.every(item => item.status === 'finish');
    },
    toggleTable() {
      this.isTableVisible = true;
    },
    closeTable() {
      this.isTableVisible = !this.isTableVisible;
    },
    showFinishItem(itemId) {
      this.selectedItemId = itemId;
      this.showModal = true;
    },
    showWorkOrderModal() {
      this.showStartWO = true;
    },
    showAddItem() {
      this.showAddItemModal = true;
    },
    showCreateWorkOrder() {
      this.showModalWO = true;
    },
    showFinishWorkOrder() {
      this.showFinishWOModal = true;
    },
    showEditComplaintModal() {
      this.showEditModal = true;
    },
    showDeleteItem(itemId) {
      this.selectedItemId = itemId;
      this.showDeleteModal = true;
    },
    closeFullComplaintImg() {
      this.showFullComplaintImg = false;
    },
    closeFullImg() {
      this.showFullImg = false;
    },
    closeDeleteItem() {
      this.showDeleteModal = false;
    },
    closeAddItem() {
      this.showAddItemModal = false;
    },
    closeFinishItem() {
      this.showModal = false;
    },
    closeStartWO() {
      this.showStartWO = false;
    },
    closeCreateWO() {
      this.showModalWO = false;
    },
    closeFinishWO() {
      this.showFinishWOModal = false;
    },
    closeEditComplaint() {
      this.showEditModal = false;
    },
    onWorkOrderMasterChanged(value) {
      this.woSelected = value;
      this.updatePayload();
    },
    onWorkOrderTypeChange(selected) {
      this.woSelected = selected;
      this.needPelanggan = selected.type?.need_pelanggan || false;
      if (this.needPelanggan) {
        this.fetchPelanggan();
      }
      this.updatePayload();
    },
    onStaffChange() {
      this.updatePayload();
    },
    woDescriptionChange() {
      this.updatePayload();
    },
    woStatusChange() {
      this.updatePayload();
    },
    timeDifference(start_at, finish_at) {
      if (start_at && finish_at) {
        const startTime = new Date(start_at);
        const finishTime = new Date(finish_at);
        const timeDifference = Math.floor((finishTime - startTime) / 60000);
        return timeDifference;
      }
      return null;
    },
    async deleteItemsImg() {
      this.deleteLoading = true;
      try {
        const response = await axios.delete(
          `${this.apiUrl}/api/file/${this.selectedImageId}`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        if (response.status == 200) {
          this.showFullImg = false;
          this.fetchCurrentWorkOrder(this.workOrderId);
          this.fetchComplaint(this.$route.params.complaintId);
          this.showAlert(`Gambar dihapus`);
        }
      } catch (error) {
        console.error('Error deleting Image:', error);
      } finally {
        this.deleteLoading = false;
      }
    },
    async fetchWorkOrderItems(searchKeyword = '') {
      clearTimeout(this.debounce);

      this.debounce = setTimeout(async () => {
        try {
          if (searchKeyword.trim() === '') {
            this.workOrderItemsList = [];
            this.showWorkOrderItems = false;
            return;
          }
          this.itemsLoading = true;
          const response = await axios.get(
            `${this.apiUrl}/api/work-order/work-order-user-items/${this.woMasterId}`,
            {
              params: {
                per_page: 20,
                page: 1,
                search: searchKeyword,
              },
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
            }
          );

          this.workOrderItemsList = response.data.data.map(item => ({
            id: item.id,
            item: item.description,
          }));
          this.showWorkOrderItems = true;
        } catch (error) {
          console.error('Error fetching work orders:', error);
          this.workOrderItemsList = [];
          this.showWorkOrderItems = false;
        } finally {
          this.itemsLoading = false;
        }
      }, 500);
    },
    selectItem(itemDescription) {
      this.newItemDescription = itemDescription;
      this.showWorkOrderItems = false;
    },
    toggleWorkOrderList() {
      this.showWorkOrderItems = !this.showWorkOrderItems;
    },
    async onPasteImageDocumentation(itemId, event) {
      let items = event.clipboardData.items;

      if (items.length > 0 && items[0].type.startsWith('image/')) {
        this.showAlert(`Sedang mengunggah file dokumentasi`);
        this.imageLoading = true;
      }

      for (const item of items) {
        if (item.type.startsWith('image/')) {
          const blob = item.getAsFile();

          const formData = new FormData();
          formData.append('status', 'finish');
          formData.append('file', blob);

          const response = await axios.post(
            `${this.apiUrl}/api/work-order/work-order-user-items/${itemId}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
            }
          );

          if (response.status === 200) {
            this.showAlert(`Berhasil mengupload file dokumentasi.`);
            this.imageLoading = false;
            setTimeout(
              () => this.fetchCurrentWorkOrder(this.workOrderId),
              3000
            );
          } else {
            this.showAlert(`Gagal mengupload file dokumentasi.`);
          }
        }
      }
    },
    onPelangganSelect(selected) {
      this.selectedPelanggan = selected;
      this.updatePayload();
    },
    async fetchToken(userId) {
      // await this.$store.dispatch('openaccess/getToken', userId);
    },
    async fetchApiUrl() {
      try {
        const response = await axios.get(`/open_access/api_url`);
        if (response.status === 200) {
          this.apiUrl = response.data.api_url;
        }
      } catch (error) {
        console.error('Error fetching API URL:', error);
      }
    },
    async fetchCmsApi() {
      try {
        const response = await axios.get(`/open_access/homenet_cms`);
        if (response.status === 200) {
          this.cmsApiUrl = response.data.api_url;
          this.cmsToken = response.data.token;
        } else {
          console.warn('Data cms api tidak ditemukan');
        }
      } catch (error) {
        console.error('Error fetching Homenet CMS data:', error);
      }
    },
    async fetchPelanggan() {
      try {
        let search = this.complaint.contacts[0].name;

        const response = await axios.get(
          `${this.apiUrl}/api/v2/pelanggan/fetch-pelanggan?search=${search}&type=default&company_id=3&per_page=5000&page=1`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        this.pelangganList = response.data.data.map(pelanggan => {
          let formatted = {
            ...pelanggan,
            full_name:
              pelanggan.user && pelanggan.user.full_name
                ? pelanggan.user.full_name
                : 'Nama tidak tersedia',
          };

          if (
            this.complaint.hasOwnProperty('complaint_has_conversations') &&
            pelanggan.pelanggan_id ==
              this.complaint.complaint_has_conversations[0]
                .openaccess_customer_id
          ) {
            this.selectedPelanggan = formatted;
          }

          return formatted;
        });
      } catch (error) {
        console.error('Error fetching pelanggan:', error);
      }
    },
    async saveNewItem() {
      this.isLoading = true;
      const payload = {
        description: this.newItemDescription,
        need_file: this.needFile ? 1 : 0,
        status: 'pending',
        type: 'add_item',
        work_order_master_id: this.woMasterId,
        work_order_user_id: this.woUserId,
      };
      try {
        const response = await axios.post(
          `${this.apiUrl}/api/work-order/work-order-user-items`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );

        this.items.push(response.data.data);
        this.closeAddItem();
        this.newItemDescription = '';
        this.needFile = false;
        this.showAlert(`Berhasil Menambahkan Item.`);
      } catch (error) {
        console.error('Error adding new item:', error);
      } finally {
        this.isLoading = false;
      }
    },
    onChangeRange(value) {
      this.customDateRange = value;
    },
    async uploadDocumentation(itemId, event) {
      this.imageLoading = true;
      try {
        const files = event.target.files;
        for (let i = 0; i < files.length; i++) {
          const formData = new FormData();
          formData.append('status', 'finish');
          formData.append('file', files[i]);

          const response = await axios.post(
            `${this.apiUrl}/api/work-order/work-order-user-items/${itemId}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
            }
          );

          if (response.status === 200) {
            this.showAlert(`Berhasil mengupload file dokumentasi`);
          } else {
            this.showAlert(`Gagal mengupload file dokumentasi`);
          }
        }
        this.fetchCurrentWorkOrder(this.workOrderId);
      } catch (error) {
        this.showAlert('Terjadi kesalahan saat mengupload file');
        console.error('Kesalahan saat menyelesaikan item work order,', error);
      } finally {
        this.imageLoading = false;
      }
    },
    async startWorkOrder() {
      this.isLoading = true;
      try {
        const workOrderId = this.workOrderId;
        const response = await axios.put(
          `${this.apiUrl}/api/work-order/work-order-users/${workOrderId}/start`,
          {},
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        if (response.status == 200) {
          this.showStartWO = false;
          this.fetchCurrentWorkOrder(workOrderId);
          this.showAlert('Berhasil memulai Work Order.');
        }
      } catch (error) {
        this.showAlert('Gagal memulai work order.');
        console.error('Kesalahan saat memulai work order:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async saveChanges() {
      this.isLoading = true;
      try {
        let utcStartDate = null;
        let utcEndDate = null;

        if (
          this.customDateRange &&
          this.customDateRange[0] &&
          this.customDateRange[1]
        ) {
          const startDate = new Date(this.customDateRange[0]);
          const endDate = new Date(this.customDateRange[1]);
          utcStartDate = startDate.toISOString();
          utcEndDate = endDate.toISOString();
        }

        const data = {
          complaint_date: this.complaintDate.split('T')[0],
          complaint_time: `${this.complaintDate.split('T')[1]}.000Z`,
          problem_id: this.issueSelected,
          status_id: this.statusSelected,
          description: this.complaintDesc,
          complaint_source_id: this.sourcesSelected,
          is_mass_complaint: this.typeSelected === 'Massal' ? 1 : 0,
          mass_complaint_description: this.massOption,
        };
        if (this.issueSelected === 8) {
          data.other_issue = this.otherIssue;
        }
        if (this.statusSelected === 4) {
          data.pending_status = this.confirmSelected;
        }
        if (this.statusSelected === 5) {
          data.completed_at = new Date().toISOString();
        }
        if (this.customDateRange) {
          data.offline_range_start_date = utcStartDate;
          data.offline_range_end_date = utcEndDate;
        }

        await this.$store.dispatch('complaints/update', {
          id: this.complaintId,
          data,
        });

        this.showEditModal = false;
        this.showAlert('Berhasil memperbaharui data komplain.');

        this.fetchComplaint(this.$route.params.complaintId);
      } catch (error) {
        this.showAlert('Gagal memperbaharui data komplain.');
        console.error(
          'Terjadi kesalahan saar mengupdate data complaint:',
          error
        );
      } finally {
        this.isLoading = false;
      }
    },
    async finishWorkOrder() {
      this.isLoading = true;
      try {
        const workOrderId = this.workOrderId;
        const response = await axios.put(
          `${this.apiUrl}/api/work-order/work-order-users/${workOrderId}/finish`,
          {
            comment: this.woComment,
            change_estimated_time: false,
            new_working_time: 0,
            current_working_time: this.estimatedTime,
            original_working_time: 20,
            real_panjang_kabel: 0,
            _method: 'PUT',
          },
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        if (response.status == 200) {
          const data = {
            status_id: 4,
            pending_status: 'Menunggu Konfirmasi Pelanggan',
          };

          await this.$store.dispatch('complaints/update', {
            id: this.complaintId,
            data,
          });
          this.showFinishWOModal = false;
          this.fetchCurrentWorkOrder(this.workOrderId);
          this.fetchComplaint(this.$route.params.complaintId);
          this.showAlert('Berhasil menyelesaikan Work Order.');
        }
      } catch (error) {
        this.showAlert('Gagal menyelesaikan Work Order.');
        console.error('Error completing work order:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async deleteWOItems() {
      this.isLoading = true;
      try {
        const response = await axios.delete(
          `${this.apiUrl}/api/work-order/work-order-user-items/${this.selectedItemId}`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        if (response.status == 200) {
          this.showDeleteModal = false;
          this.fetchCurrentWorkOrder(this.workOrderId);
          this.showAlert('Item Work Order Dihapus.');
        }
      } catch (error) {
        this.showAlert('Gagal menghapus item WO.');
        console.error('Error deleting action:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async finishWOItems() {
      this.isLoading = true;
      try {
        const formData = new FormData();
        formData.append('status', 'finish');
        formData.append('file', '');

        const response = await axios.post(
          `${this.apiUrl}/api/work-order/work-order-user-items/${this.selectedItemId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        if (response.status == 200) {
          this.showModal = false;
          this.fetchCurrentWorkOrder(this.workOrderId);
          this.showAlert('Berhasil Finish Item Work Order.');
        }
      } catch (error) {
        this.showAlert('Gagal Finish Item Work Order.');
        console.error('Error finishing action:', error);
      } finally {
        this.isLoading = false;
      }
    },
    updatePayload() {
      const selectedWo = this.woSelected;
      if (selectedWo && this.staffSelected) {
        this.payload = {
          add_more_item: selectedWo.add_more_item,
          can_multiple_active: selectedWo.can_multiple_active,
          change_estimated_time: selectedWo.change_estimated_time,
          created_at: selectedWo.created_at,
          created_by: selectedWo.created_by,
          deleted_at: null,
          description: this.workOrderDescription,
          id: selectedWo.id,
          items: selectedWo.items.map(item => ({
            id: item.id,
            description: item.description,
            need_file: item.need_file,
            order: item.order,
          })),
          name: selectedWo.name,
          pelanggan_id: this.selectedPelanggan
            ? this.selectedPelanggan.pelanggan_id
            : null,
          name: selectedWo.name,
          need_customer_visit: selectedWo.need_customer_visit,
          require_real_panjang_kabel: selectedWo.require_real_panjang_kabel,
          require_ttd_pelanggan: selectedWo.require_real_panjang_kabel,
          status: this.woStatusSelected,
          type_id: selectedWo.type_id,
          user_id: this.staffIds,
          work_order_master_id: selectedWo.key,
          working_time: selectedWo.working_time,
          start_at: this.formatDate(new Date()),
        };
      }
    },
    async getStaffTeknisi() {
      try {
        const response = await axios.get(
          `${this.apiUrl}/api/user/staff/pagination?page=1&per_page=36&order_by=&order=ascending&search=&company_id=3&role=18&position=`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        this.staffs = response.data.data.map(staff => ({
          id: staff.id,
          full_name: staff.full_name,
        }));

        let staffFounded = response.data.data.find(
          staff => staff.id == this.openaccessUserData.id
        );

        if (staffFounded) {
          this.staffSelected.push(staffFounded);
        }
      } catch (error) {
        console.error('Error fetching work order types:', error);
      }
    },
    async createWorkOrder() {
      this.isLoading = true;
      try {
        const response = await axios.post(
          `${this.apiUrl}/api/work-order/work-order-users`,
          this.payload,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        const workOrderId = response.data.data.id;

        const data = {
          work_order_id: workOrderId,
        };

        if (response.status === 200) {
          await this.$store.dispatch('complaints/update', {
            id: this.complaintId,
            data,
          });
          this.showAlert('Berhasil membuat Work Order.');
          this.showModalWO = false;
          location.reload();
        }
      } catch (error) {
        this.showAlert('Gagal membuat Work Order.');
        console.error('Gagal mengirim data:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchCurrentWorkOrder(workOrderId) {
      try {
        const response = await axios.get(
          `${this.apiUrl}/api/work-order/work-order-users/detail-wo/${workOrderId}`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        const currentWO = response.data.data;
        this.workOrderName = currentWO.name;
        this.workOrderDescription = currentWO.description;
        this.workingStaff = currentWO.users
          .map(user => user.full_name)
          .join(', ');
        this.createdBy = currentWO.created_user.full_name;
        this.estimatedTime = currentWO.working_time;
        this.startAt = currentWO.start_at;
        this.finishAt = currentWO.finish_at;
        this.woStatus = currentWO.status;
        this.woStatusText = currentWO.status_text;
        this.items = currentWO.items || [];
        this.timeDiff = this.timeDifference(
          currentWO.start_at,
          currentWO.finish_at
        );
        this.finishWONote = currentWO.comment;
        this.woMasterId = currentWO.work_order_master_id;
        this.woUserId = currentWO.id;
        if (currentWO.validated) {
          this.validatedUser = {
            full_name: currentWO.validated.full_name,
            validated_at: currentWO.validated_at,
          };
        } else {
          this.validatedUser = {};
        }

        if (this.woMasterId) {
          this.fetchWorkOrderItems();
        }

        if (currentWO.pelanggan != null) {
          const pelangganName = currentWO.pelanggan.user.full_name;
          const siteId = currentWO.pelanggan.presale.presale_master.site_id;
          this.pelangganName = pelangganName;
          this.siteId = siteId;
        } else {
          return null;
        }
      } catch (error) {
        console.error('Error fetching work order:', error);
      }
    },

    async onSearchChange(value) {
      try {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(async () => {
          const response = await axios.get(
            `${this.apiUrl}/api/v2/pelanggan/fetch-pelanggan?search=${value}&type=default&company_id=3&per_page=2000&page=1`,
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
            }
          );
          this.pelangganList = response.data.data.map(pelanggan => {
            return {
              ...pelanggan,
              full_name:
                pelanggan.user && pelanggan.user.full_name
                  ? pelanggan.user.full_name
                  : 'Nama tidak tersedia',
            };
          });
        }, 500);
      } catch (error) {
        console.error('Error fetching pelanggan:', error);
      }
    },
    async syncCustomerData(phoneNumber) {
      try {
        const formattedPhoneNumber = phoneNumber.replace(/^\+62/, '0');
        const response = await axios.get(
          `${this.cmsApiUrl}/api/v1/pelanggan/find-by-phone-number/${formattedPhoneNumber}`,
          {
            headers: {
              Authorization: `Bearer ${this.cmsToken}`,
            },
          }
        );

        if (response.status === 200) {
          const data = response.data.data;
          this.firstName = data.first_name;
          this.custAddress = data.pelanggan.alamat;

          const subscription = data.pelanggan.subscriptions.find(
            item => item.id === this.paketBerjalanId
          );

          this.packageName = subscription
            ? subscription.paket_layanan.nama_paket
            : 'Tidak ada paket ditemukan';
        }
      } catch (error) {
        console.error('Error fetching work order:', error);
      }
    },
    async fetchWorkOrderTypes() {
      try {
        const response = await axios.get(
          `${this.apiUrl}/api/work-order/work-order-masters?page=1&per_page=1000&type_id=&company_id=3&with=type,items`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        this.wo = response.data.data.map(wot => ({
          key: wot.id,
          name: wot.name,
          working_time: wot.working_time,
          items: wot.items,
          add_more_item: wot.add_more_item,
          can_multiple_active: wot.can_multiple_active,
          change_estimated_time: wot.change_estimated_time,
          company_id: wot.company_id,
          created_at: wot.created_at,
          created_by: wot.created_by,
          description: wot.description,
          need_customer_visit: wot.need_customer_visit,
          require_ttd_pelanggan: wot.require_ttd_pelanggan,
          require_real_panjang_kabel: wot.require_real_panjang_kabel,
          type_id: wot.type_id,
          status: 'pending',
          type: {
            ...wot.type,
            need_pelanggan: wot.type?.need_pelanggan || false,
          },
        }));
      } catch (error) {
        console.error('Error fetching work order types:', error);
      }
    },
    async fetchIssues() {
      try {
        const response = await axios.get('/problems');
        this.issues = response.data.map(problem => ({
          key: problem.id,
          name: problem.name,
        }));
      } catch (error) {
        console.error('Error fetching issues:', error);
      }
    },
    async fetchSources() {
      try {
        const response = await axios.get('/complaint_sources');
        this.sources = response.data.map(source => ({
          key: source.id,
          name: source.name,
        }));
      } catch (error) {
        console.error('Error fetching issues:', error);
      }
    },
    formatDate(date) {
      const padZero = num => num.toString().padStart(2, '0');
      const month = padZero(date.getMonth() + 1);
      const day = padZero(date.getDate());
      const year = date.getFullYear();
      const hours = padZero(date.getHours());
      const minutes = padZero(date.getMinutes());
      const seconds = padZero(date.getSeconds());

      return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
    },
    async fetchComplaint(id) {
      try {
        await this.$store.dispatch('complaints/show', id);

        const data = this.complaint;

        if (data.hasOwnProperty('contacts')) {
          if (data) {
            this.listConversations = data.conversations;
          }
          const conversations = data.conversations;
          const contacts = data.contacts;

          if (contacts.length > 1) {
            this.userName = `${contacts[0].name}, + ${
              contacts.length - 1
            } lainnya`;
          } else {
            this.userName = contacts[0].name;
          }

          this.complaintDate = `${data.complaint_date}T${
            data.complaint_time.split('T')[1].split('.')[0]
          }`;
          this.issueSelected = data.problem_id;
          this.sourcesSelected = data.complaint_source_id;
          this.statusSelected = data.status_id;
          this.complaintDesc = data.description;
          this.typeSelected = data.is_mass_complaint ? 'Massal' : 'Individu';
          this.massOption = data.mass_complaint_description;
          this.complaintId = id;
          this.convId =
            conversations.length > 0 ? conversations[0].display_id : null;
          this.workOrderId = data.work_order_id;
          this.uploadedImages = JSON.parse(data.medias);
          this.otherIssue = data.other_issue;
          this.confirmSelected = data.pending_status;

          if (data.offline_range_start_date && data.offline_range_end_date) {
            const startDate = new Date(data.offline_range_start_date);
            const endDate = new Date(data.offline_range_end_date);

            this.customDateRange = [startDate, endDate];
          }

          this.paketBerjalanId =
            data.complaint_has_conversations[0].paket_berjalan_id;

          for (const customer_has_conversation of data.complaint_has_conversations) {
            if (customer_has_conversation.customer_id !== null) {
              this.customerId = customer_has_conversation.customer_id;
              this.oaCustomerId =
                customer_has_conversation.openaccess_customer_id;

              this.phoneNumber = customer_has_conversation.contact.phone_number;

              if (this.customerId && this.phoneNumber) {
                await this.fetchCmsApi();
                if (this.cmsApiUrl && this.cmsToken) {
                  await this.syncCustomerData(this.phoneNumber);
                }
              }
              break;
            }
          }
          if (!this.customerId) {
            this.phoneNumber = contacts[0].phone_number;
          }
        }
      } catch (error) {
        console.error('Error fetching complaint details:', error);
      }
    },
    async getPendingStatus() {
      axios
        .get(`/complaints/${this.complaintId}`)
        .then(response => {
          const complaint = response.data;
          this.statusSelected = complaint.status_id;

          if (this.statusSelected === 4) {
            this.confirmSelected = complaint.pending_status;
          }
        })
        .catch(error => {
          console.error('Gagal memuat data komplain:', error);
        });
    },
    async initPageData() {
      try {
        const id = this.$route.params.complaintId;
        this.fetchIssues();
        this.fetchSources();
        this.$store.dispatch('complaints/fetchStatus');

        await this.fetchApiUrl();
        await this.fetchToken(this.currentUserID);
        this.fetchWorkOrderTypes();
        this.getStaffTeknisi();
        await this.fetchComplaint(id);

        if (this.workOrderId) {
          this.fetchCurrentWorkOrder(this.workOrderId);
        }
      } catch (error) {
        console.error('Error initializing data', error);
      }
    },
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
  },
  mounted() {
    this.initPageData();
    this.$store.dispatch('complaints/fetchStatus');
  },
};
</script>

<style scoped>
.conversation-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-height: max-content;
  margin-bottom: 20px;
}

.custom-multiselect {
  max-width: 100%;
  white-space: normal;
}

.multiselect__tags {
  display: flex;
  flex-wrap: wrap;
}

.multiselect__tag {
  white-space: nowrap;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.wo.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.wo.list-group-item {
  background-color: hsl(200, 7%, 8.8%);
  border: 1px solid #697076;
  /* padding: 0.75rem 1.25rem; */
  position: center;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  color: white;
}

.wo.list-group-item:hover {
  background-color: hsl(200, 7%, 12%);
}
</style>
