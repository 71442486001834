import types from "../../mutation-types";
import Vue from 'vue';

export const mutations = {
    [types.SET_OPENACCESS_UI_FLAG]($state, data) {
        $state.uiFlags = {
            ...$state.uiFlags,
            ...data,
        };
    },
    [types.SET_OPENACCESS_TOKEN]: ($state, token) => {
        Vue.set($state, 'token', token)
    },
    [types.SET_OPENACCESS_USER_DATA]: ($state, data) => {
        Vue.set($state, 'userData', data)
    },
    [types.SET_OPENACCESS_API_URL]: ($state, data) => {
        Vue.set($state, 'apiUrl', data)
    },
    [types.SET_OPENACCESS_WORK_ORDER_TYPE]: ($state, data) => {
        Vue.set($state.workOrder, 'types', data)
    },
    [types.SET_OPENACCESS_WORK_ORDER_MASTERS]: ($state, data) => {
        Vue.set($state.workOrder, 'masters', data)
    },
    [types.SET_OPENACCESS_WORK_ORDER_RECORDS]: ($state, data) => {
        Vue.set($state.workOrder, 'records', data)
    },
    [types.SET_OPENACCESS_WORK_ORDER_META]: ($state, meta) => {
        Vue.set($state.meta, 'currentPage', meta.current_page);
        Vue.set($state.meta, 'lastPage', meta.last_page);
        Vue.set($state.meta, 'total', meta.total);
        Vue.set($state.meta, 'perPage', (meta.per_page));
    },
    [types.SET_OPENACCESS_STAFF_LIST]: ($state, data) => {
        Vue.set($state, 'staffs', data)
    },
    [types.SET_OPENACCESS_PELANGGAN_LIST]: ($state, data) => {
        Vue.set($state, 'pelanggans', data)
    },
}