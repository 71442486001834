import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

const state = {
    token: '',
    userData: {},
    apiUrl: '',
    staffs: [],
    pelanggans: [],
    workOrder: {
        types: [],
        masters: [],
        records: []
    },
    meta: {
        currentPage: 1,
        lastPage: 1,
        total: 0,
        perPage: 10
    },
    uiFlags: {
        isFetching: false,
        isFetchingWorkOrderTypes: false,
        isFetchingWorkOrderMasters: false,
        isFetchingWorkOrderRecords: false,
        isFetchingStaffTeknisi: false,
        isFetchingPelanggan: false,
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
