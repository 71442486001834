import types from '../../mutation-types';

export const actions = {
    getToken: async ({ commit }) => {
        commit(types.SET_OPENACCESS_UI_FLAG, { isFetching: true });
        try {
            
            const {
                data: {
                    token,
                    data,
                    api_url
                }
            } = await axios.get(`/open_access_token`);

            commit(types.SET_OPENACCESS_TOKEN, token);
            commit(types.SET_OPENACCESS_USER_DATA, data);
            commit(types.SET_OPENACCESS_API_URL, api_url);
            commit(types.SET_OPENACCESS_UI_FLAG, { isFetching: false });
        } catch (error) {
            console.error(error)
            commit(types.SET_OPENACCESS_UI_FLAG, { isFetching: false });
        }
    },
    getWorkOrderType: async ({commit, state}, params) => {
        commit(types.SET_OPENACCESS_UI_FLAG, { isFetchingWorkOrderTypes: true });
        const {
            data: {
                data
            }
        } = await axios.get(
            `${state.apiUrl}/api/work-order/work-order-master-type`,
            {
                params: {
                    current_page: 1,
                    per_page: 1000,
                    company_id: 3,
                    order_type: 'type',
                    order: 'ascending',
                },
                headers: {
                    Authorization: `Bearer ${state.token}`,
                },
            }
        );

        commit(types.SET_OPENACCESS_WORK_ORDER_TYPE, data);
        commit(types.SET_OPENACCESS_UI_FLAG, { isFetchingWorkOrderTypes: false });
    },
    getWorkOrderMaster: async ({commit, state}, type_id = null) => {
        commit(types.SET_OPENACCESS_UI_FLAG, { isFetchingWorkOrderMasters: true });

        const url = `${state.apiUrl}/api/work-order/work-order-masters?page=1&per_page=1000&company_id=3&with=type,items` +
        (type_id ? `&type_id=${type_id}` : '');

        const {
            data: {
                data
            }
        } = await axios.get(
            url,
            {
                headers: {
                    Authorization: `Bearer ${state.token}`,
                },
            }
        );
        
        commit(types.SET_OPENACCESS_WORK_ORDER_MASTERS, data)
        commit(types.SET_OPENACCESS_UI_FLAG, { isFetchingWorkOrderMasters: false });
    },
    getWorkOrderRecordList: async ({ commit, state }, { page = 1, filters = {} }) => {
        commit(types.SET_OPENACCESS_UI_FLAG, { isFetchingWorkOrderRecords: true });

        const params = {
            date_wo: new Date().toISOString().split('T')[0],
            company_id: 3,
            per_page: 10,
            order_by: 'start_at',
            order: 'descending',
            page: page,
            status: filters.selectedStatus ? filters.selectedStatus.key : '',
            with: 'users,validated,histories,to_company,fromCompany',

            ...filters.dateRange && filters.dateRange.length === 2 && {
                'start_at[0]': filters.dateRange[0].toISOString().split('T')[0],
                'start_at[1]': filters.dateRange[1].toISOString().split('T')[0]
            },

            ...filters.staffSelected && filters.staffSelected.length > 0 && {
                'user_id[]': filters.staffSelected.map(staff => staff.id)
            },

            ...filters.pelanggan_id && { pelanggan_id: filters.pelanggan_id },
            ...filters.selectedWorkOrderType && { 'type_id': filters.selectedWorkOrderType.id },
            ...filters.selectedWorkOrderMaster && { 'work_order_master_id': filters.selectedWorkOrderMaster.id }
        };

        try {
            const {
                data: {
                    data,
                    meta
                }
            } = await axios.get(`${state.apiUrl}/api/work-order/work-order-users/list/list-wo`, {
                params: params,
                headers: {
                    Authorization: `Bearer ${state.token}`,
                },
            });

            commit(types.SET_OPENACCESS_WORK_ORDER_RECORDS, data);
            commit(types.SET_OPENACCESS_WORK_ORDER_META, meta);
            commit(types.SET_OPENACCESS_UI_FLAG, { isFetchingWorkOrderRecords: false });
        } catch (error) {
            console.error(error);
            commit(types.SET_OPENACCESS_UI_FLAG, { isFetchingWorkOrderRecords: false });
        }
    },
    getStaffTeknisiList: async ({ commit, state}) => {
        commit(types.SET_OPENACCESS_UI_FLAG, { isFetchingStaffTeknisi: true });

        try {
          const response = await axios.get(
            `${state.apiUrl}/api/user/staff/pagination`,
            {
                params: {
                    page: 1,
                    per_page: 50,
                    company_id: 3,
                    role: 18,
                    order: 'ascending',
                },
                headers: {
                    Authorization: `Bearer ${state.token}`,
                },
            }
          );
          
          const data = response.data.data.map(staff => ({
            id: staff.id,
            full_name: staff.full_name,
          }));

          commit(types.SET_OPENACCESS_STAFF_LIST, data);
          commit(types.SET_OPENACCESS_UI_FLAG, { isFetchingStaffTeknisi: false });
        } catch (error) {
          console.error(error);
        }
    },
    getPelangganList: async ({ commit, state }, query = '') => {
        commit(types.SET_OPENACCESS_UI_FLAG, { isFetchingPelanggan: true });

        try {
          const response = await axios.get(
            `${state.apiUrl}/api/v2/pelanggan/fetch-pelanggan`,
            {
                params: {
                    search: query,
                    type: 'default',
                    company_id: 3,
                    page: 1,
                    per_page: 10,
                },
                headers: {
                    Authorization: `Bearer ${state.token}`,
                },
            }
          );
  
          const data = response.data.data.map(pelanggan => {
            return {
              ...pelanggan,
              full_name:
                pelanggan.user && pelanggan.user.full_name
                  ? pelanggan.user.full_name
                  : 'Nama tidak tersedia',
            };
          });

          commit(types.SET_OPENACCESS_PELANGGAN_LIST, data);
          commit(types.SET_OPENACCESS_UI_FLAG, { isFetchingPelanggan: false });
        } catch (error) {
          console.error(error);
        }
      },
}