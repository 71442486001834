export const getters = {
    getToken($state) {
        return $state.token
    },
    getUserData($state) {
        return $state.userData
    },
    getApiUrl($state) {
        return $state.apiUrl
    },
    getWorkOrder($state) {
        return $state.workOrder
    },
    getWorkOrderMeta($state) {
        return $state.meta
    },
    getStaff($state) {
        return $state.staffs
    },
    getPelanggan($state) {
        return $state.pelanggans
    },
    getUiFlags($state) {
        return $state.uiFlags
    },
}